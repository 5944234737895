.k_trainer_in_button {
    align-items: center;
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

img.k_trainer_in_button_icon {
    height: 24px;
    margin-right: 8px;
    width: 24px;
}

.k_trainer_in_button_wrapper {
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.k_trainer_in_button_title {
    color: #222;
    line-height: 100%;
    margin: 0 0 8px;
}

.k_trainer_in_button_title_no_desc {
    margin: 0;
}

.k_cart_trainer_premium {
    cursor: pointer;
}

.history-info-duration {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-left: 8px;
    opacity: .5;
    text-align: left;
    display: flex;
}

.history-info-duration-num {
    margin-right: 5px;
}

.k_trainer_in_button_title_no_desc {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1280px) {
    .k_trainer_in_button {
        display: flex;
    }
}

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 992px) {
    .k_trainer_in_button {
        border-radius: 12px;
    }

    .k_trainer_in_button_title {
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
    }
}

@media screen and (max-width: 450px) {
    .k_trainer_in_button_title {
        font-size: 14px;
        margin-bottom: 4px;
    }

}