.footer_container {
    border-top: 2px solid #fff;
    display: flex;
    justify-content: space-between;
    padding: 38px 0 53px;
    position: relative;
    z-index: 5;
}

.footer_logo {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.footer_logo {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    display: flex;
}

.footer_logo img {
    max-width: 129px;
}

img.footer_studio {
    max-width: 127px!important;
}

.k_footer_container_version {
    opacity: .5;
    position: relative;
    text-align: left;
}

footer.footer.footer_control {
    margin-top: auto;
}

.footer_control .footer_container {
    border: none;
}

.footer_logo {
    align-items: center;
}

.footer_container {
    padding: 53px 0 53px;
}

a.status_url, .k_footer_container_version, .k_footer_container_version + a {
    display: flex;
    height: 24px;
    align-items: center;
}

@media screen and (max-width: 992px){
    footer.footer {
        display: none;
    }
}