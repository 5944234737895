.copy_number_id {
    margin: 0 6px 0 0;
    cursor: pointer;
}

.attr_id_trainer .single_page_body_content_inner_top_list_attr_one_text {
    overflow: visible;
    width: fit-content;
}

@media screen and (max-width: 768px) {
    .copy_number_id {
        font-size: 14px;
    }
}