.MuiIconButton-edgeEnd {
    background-image: url(../../../assets/icons/form/form_eye_icon.svg) !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    display: none;
    height: 20px !important;
    position: absolute;
    right: 13px;
    width: 20px !important;
    padding: 0px !important;
    margin-right: -12px !important;
}

.MuiIconButton-edgeEnd.active{
    background-image: url(../../../assets/icons/form/form_eye_off_icon.svg) !important;
}

.MuiIconButton-edgeEnd .MuiSvgIcon-root {
    display: none;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    font-family: Inter !important;
    font-weight: 500 !important;
    line-height: 16.94px !important;
    font-size: 16px !important;
    letter-spacing: normal;
    transition: 0.2s !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    font-size: 14px !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink,
.fixed_autofull .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
    top: 9px !important;
    left: 2px !important;
    padding-top: 14px;
    transform: translate(14px, -9px) scale(0.75);
}

.auth__input:not(:placeholder-shown) + label, .auth__input:focus + label, .auth__input[aria-invalid="true"] + label, .auth__input:-webkit-autofill + label {
    top: 9px;
    font-size: 12px;
}

.MuiInputBase-root.MuiOutlinedInput-root input {
    font-size: 14px !important;
    font-weight: 550 !important;
    padding: 28px 16px 9px !important;
    font-family: Inter !important;
    letter-spacing: -0.1px;
}

.MuiInputBase-root.MuiOutlinedInput-root {
    height: 57px !important;
    overflow: hidden !important;
}