.payment_receipt {
    margin-bottom: 25px;
    margin-top: 50px;
    max-width: 420px;
    width: 100%;
}

.payment_receipt_open_button {
    background: #222;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    max-width: 420px;
    padding: 25px 14px;
    width: 100%;
}

.payment_receipt_open_button_title {
    color: #f8f8f8;
}

.payment_receipt_open_button_icon {
    align-self: center;
    background: url("../../../assets/icons/base_arrow_left.svg") no-repeat 50%;
    border: none;
    cursor: pointer;
    filter: brightness(100);
    height: 26px;
    width: 26px;
    transform: rotate(270deg);
    transition: 0.3s;
}

.payment_receipt_open_button_icon.open {
    transform: rotate(90deg);
}

.payment_receipt_open_block {
    background: #222;
    border-radius: 0 0 10px 10px;
    margin-top: -34px;
    max-width: 420px;
    padding: 39px 14px 33px;
    width: 100%;
}

.payment_receipt_open_block_table{
    column-gap: 30px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    row-gap: 20px;
}

.payment_receipt_open_block_flex_left {
    color: #c1c1c1;
    white-space: pre;
}

.payment_receipt_open_block_flex_right a {
    align-items: center;
    color: #5589f1;
    text-decoration: none;
}

.payment_receipt_open_block_flex_right {
    color: #fff;
}

.payment_receipt_open_block .style_1_base_button_payment{
    display: none;
}

.payment_receipt_open_block_title{
    display: none;
}

@media screen and (max-width: 480px) {
    @media screen and (max-height: 550px){
        button.style_1_base_button_payment {
            display: none;
        }
    }

    .payment_receipt {
        margin-bottom: 12px;
        margin-top: 25px;
        height: auto;
        max-width: 100%;
    }

    .payment_receipt_open_button {
        max-width: 100%;
    }

    .payment_receipt_open_block {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 999;
        padding: 40px 14px 12px 14px;
        max-width: 100%;
    }

    .payment_receipt_open_block:after{
        background-color: #222;
        content: "";
        height: 100vh;
        left: 0;
        opacity: .5;
        position: absolute;
        top: -100vh;
        width: 100vw;
        z-index: -1;
    }

    .payment_receipt_open_block_flex_right {
        text-align: right;
    }

    .payment_receipt_open_block .style_1_base_button_payment {
        margin-top: 32px;
        display: block;
    }

    .payment_receipt_open_button_icon_close {
        align-self: center;
        background: url(../../../assets/icons/base_close_vector_icon.svg) no-repeat 50%;
        border: none;
        cursor: pointer;
        height: 26px;
        width: 26px;
        transform: rotate(270deg);
        transition: 0.3s;
        filter: invert(1);
    }

    button.payment_receipt_open_button_icon_close {
        position: absolute;
        right: 12px;
        top: 12px;
        width: 24px;
        height: 24px;
        padding: 0px;
    }

    .payment_receipt_open_block {
        border-radius: 12px 12px 0px 0px;
    }

    .payment_receipt_open_block_title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 32px;
    }
}