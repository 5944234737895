.payment_page {
    margin: 0 -12px;
}

.payment_page_content {
    align-items: center;
    background-color: #333;
    display: flex;
    flex: auto;
    flex-direction: column;
}

.payment_page {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.payment_form_card_back {
    width: 420px;
    display: flex;
}

.payment_form_card_back_wrapper {
    align-items: center;
    color: grey;
    display: flex;
    gap: 10px;
    margin-top: 30px;
    width: fit-content;
    justify-content: flex-start;
    text-decoration: none;
}

.payment_form_card_back_img {
    filter: contrast(.5);
    height: 13px;
    width: 7px;
}

.payment_page {
    margin: 0 -40px;
}

@media screen and (max-width: 480px) {
    .payment_page {
        margin: 0;
    }

    main.payment_page_content {
        overflow: hidden;
        margin: auto !important;
        width: 100%;
    }

    .payment_form_card_back{
        display: none;
    }

    .payment_page_content {
        padding-inline: 12px;
        padding-bottom: 20px;
        margin: 0 12px;
    }
}


/* Старые стили */

.pay__header-v2 {
    background: #222222;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pay__header-logo-v2 {
    display: flex;
    gap: 5px;
    margin: 0 auto;
    max-width: 420px;
    width: 100%;
}

.pay__header-logoimage {
    width: 30px;
    height: 30px;
}

.pay__wrap-box-logo {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.pay__main-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
}

.payment__cardnumber-defolt {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 75px;

    padding: 10px 16px;
    padding-top: 32px;


    border-radius: 6px;
    background: #454545;
    border: 1px solid #454545;
    color: #ffffff;

    max-width: 420px;
    width: 100%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: border 0.3s ease-in;
}
.payment__cardnumber-defolt:hover {
    border: 1px solid #ffffff;
}

.payment__cardnumber-defolt:focus {
    background: #222222;
    outline: none;
    border: 1px solid #ffffff;
}

.pay__main-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    /* identical to box height */
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
}

.pay__main-v2 {
    flex: auto;
    display: flex;
    flex-direction: column;
    background-color: #333333;
    align-items: center;
}

.pay__select-block {
    max-width: 420px;
    width: 100%;
    background: #222222;
    border-radius: 10px;
    display: flex;
    padding: 25px 14px;
    justify-content: space-between;
}

.payment__info-text-new {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    text-align: center;
    /* background */
    color: #F8F8F8;
}

.pay__form-container-v2 {
    max-width: 420px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.pay__payform-v2 {
    width: 100%;
    padding: 25px 20px 40px;
    background-color: #222222;
    border-radius: 10px;
}

.pay__pay-header-v2 {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    /* background */
    color: #F8F8F8;
    margin-bottom: 25px;
}

.pay__input-box-v2 {
    display: flex;
    position: relative;
    margin-bottom: 15px;
    flex-direction: column;
}

.pay__form-label-v2 {
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #FFFFFF;
    top: 12px;
    left: 16px;
}

.pay__cardpay-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 16px;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    background: #222222;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    height: 57px;
    outline: none;
}

.pay-inputs-box {
    width: 100%;
    display: flex;
    gap: 15px;
}
.pay-btn, .pay-btn_mobile {
    font-family: 'Inter';
    font-style: normal;
    background: #FFFFFF;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #222222;
    outline: none;
    border: none;
    padding: 20px;
    width: 100%;
}
button.pay-btn.pay-btn_desk {
    cursor: pointer;
}
.pay-btn_disable {
    opacity: 0.5;
    cursor: default;
}
.pay-btn_mobile {
    display: none;
}
.pay_base-input-v2 {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding: 30px 16px 10px 16px;

    background: #454545;
    border-radius: 6px;
    border: 1px solid #454545;
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #FFFFFF;
    outline: none;
    text-transform: uppercase;
    transition: border 0.3s ease-in;
}
.pay_base-input-v2:hover {
    border: 1px solid #FFFFFF;
}

.pay_base-input-v2:focus {
    background-color: #222222;
    border: 1px solid #FFFFFF;
}

.pay_base-input-v2:focus::placeholder {
    display: none;
}

.pay__sub-button {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    width: 100%;
    background: #FFFFFF;
    opacity: 0.5;
    border-radius: 6px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #222222;
}

.pay__comeback-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #808080;
    gap: 10px;
    max-width: max-content;
}

.payment__height {
    height: 191px;
}

.pay__comeback-image {
    width: 7px;
    height: 13px;
    filter: contrast(0.5);
}

.payment__info-button-v2 {
    width: 26px;
    height: 26px;
    /*background: url('../../images/content-img.svg');*/
    border: none;
    filter: brightness(100);
    background-position: center;
    background-repeat: no-repeat;
    align-self: center;
    cursor: pointer;
}

.pay__cardtypeimage {
    display: block;
    position: absolute;
    width: 50px;
    height: 30px;
    top: 15px;
    right: 16px;
}
.pay__cardtypeimage_h {
    display: none;
    opacity: 0;
}

.pay__invisible-imagev2 {
    display: none;
}

.pay__mistake-v2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #E75149;
    margin-top: 7px;
    align-self: flex-start;
}

.pay__sub-buttom-valid {
    opacity: 1
}

.payment__info-button-v2-rotate {
    transform: rotate(180deg);
}

.payment__sublock-wrapper-v2 {
    max-width: 420px;
    /* position: relative; */
    margin-bottom: 25px;
    margin-top: 50px;
    width: 100%;
}

.payment__mobile-block {
    display: none;
}

.pay__sub-button-down-page {
    display: none;
}

.payment__paytitle {
    display: none;
}

.payment__subblock {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 33px 14px;
    column-gap: 30px;
    background: #222222;
    border-radius: 0 0 10px 10px;
    row-gap: 20px;
    max-width: 420px;
    width: 100%;
    margin-top: -34px;
    padding-top: 39px;
}

.pay__mistake-v2-invisible {
    display: none;
}

.pay__red-border {
    border: 1px solid #E75149;
}

.pay__comeback-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #808080;
    gap: 10px;
    max-width: max-content;
}

.payment__subblock-invisible-state-v2 {
    display: none;
}

@media screen and (max-width: 450px) {
    .pay__form-container-v2 {
        display: contents;
    }
    .pay-btn_mobile {
        display: block;
        margin-top: auto;
    }
    .pay-btn_desk {
        display: none;
    }
    .payment__subblock {
        display: none;
    }
    .payment__mobile-block {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        align-items: flex-end;
        z-index: 100;
        background-color: #222222;
        padding-top: 5px;
        border-radius: 15px 15px 0px 0px;
    }
    .payment__mobile-block::after {
        content: "";
        position: absolute;
        top: -80vh;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        background-color: #222222;
        opacity: 0.5;
    }
    .pay__main-v2 {
        padding-inline: 12px;
        padding-bottom: 20px;
    }
    .pay__header-v2 {
        display: none;
    }
    .pay__comeback-button {
        display: none;
    }
    .pay__sub-button {
        display: none;
    }
    .pay__input-box-last-of {
        margin-bottom: 0;
    }
    .payment__paytitle {
        display: flex;
        width: 100%;
        align-items: center;
        max-width: 420px;
        margin: 0 auto;
        justify-content: center;
        position: relative;
        margin-top: 47px;
    }
    .payment__sublock-wrapper-v2 {
        margin-top: 25px;
        margin-bottom: 12px;
    }
    .payment__paybutton-top {}
    .payment__title-top {}
    .payment__height {
        height: auto;
    }
    .pay__payform-v2 {
        padding-bottom: 28px;
    }
    .pay__sub-button-down-page {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        background: #FFFFFF;
        opacity: 0.5;
        border-radius: 6px;
        border: none;
        color: #222222;
        position: fixed;
        bottom: 20px;
        width: calc(100% - 24px);
    }
    .pay__sub-buttom-valid {
        opacity: 1;
    }
}

@media screen and (max-height: 620px) {
    .pay__sub-button-down-page {
        margin-top: 20px;
        position: relative;
        width: 100%;
    }
}

/* Новые стили */


.payment_page {
    flex: 1 1;
    text-align: left;
}