.style_1_interactive_button_link {
    transition: 0.2s;
    background: #FFFFFF;
    border: 1px solid #ffffff;
    border-radius: 15px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
    color: #222222;
}

.style_1_interactive_button_link:hover, .style_1_interactive_button_link.active {
    border: 1px solid #ED6F2D;
}

.style_1_interactive_button_link:hover {
    color: #ED6F2D;
}

#filter_type_search_1{
    color: black;
}

.style_1_interactive_button_link .interactive_button .wrapper_img {
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 10px;
}

.interactive_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 24px 5px 10px;
}

/* Кастомные стили для кнопок */

/* Кнопка логотипа в шапке */
.header_interactive_button_logo  {
    width: 60px;
    height: 60px;
    font-family: 'Act of Rejection';
    font-style: normal;
    font-weight: 400;
    font-size: 30.7551px;
    line-height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(42.24deg, #ED6F2D 30%, #FFD600 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
    margin-right: 40px !important;
}

.header_interactive_button_logo:before {
    background: white;
    display: block;
    content: '';
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: -1;
    border-radius: 15px;
}

.header_interactive_button_logo .interactive_button{
    padding: 0px !important;
}

/* Кнопка "Тренеры" в шапке */
a.header_interactive_button_link_to_trainers img.interactive_button_img {
    position: relative;
    top: -1px;
    width: 33.32px;
    height: 25.17px;
}

.header_interactive_button_link_to_trainers>.interactive_button {
    padding-left: 0px;
    padding-right: 29px;
}

.header_interactive_button_link_to_trainers {
    width: 161px;
}

/* Кнопка "Покемоны" в шапке */
.header_interactive_button_link_to_pokemons {
    width: 174px;
}

/* Кнопка "Рейтинг" в шапке */
.header_interactive_button_link_to_rating {
    width: 155px;
}

/* Кнопка выхода в шапке */
.header_interactive_button_exit>.interactive_button>.wrapper_img {
    margin: 0;
}

.header_interactive_button_exit>.interactive_button {
    padding: 0px 5px;
}

/* Кнопка перехода на страницу тренера в шапке */
.header_card_trainer {
    cursor: pointer;
}