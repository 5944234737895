.k_cart_trainer_export {
    align-items: center;
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.k_mobile .k_cart_trainer_export {
    display: none;
}

.k_cart_trainer_export {
    min-height: 73px;
}

button.k_cart_trainer_export_button.disable {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}

img.k_cart_trainer_export_icon {
    height: 24px;
    margin-right: 8px;
    width: 24px;
}

.k_cart_trainer_export_box {
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.k_cart_trainer_export_box_title {
    color: #222;
    line-height: 100%;
    margin: 0 0 8px;
}

.k_cart_trainer_export_box_desc {
    color: #222;
    line-height: 15px;
    margin: 0;
}

.k_cart_trainer_export_button, .k_cart_trainer_export_button_border {
    align-items: center;
    display: flex;
    justify-content: center;
}

.k_cart_trainer_export_button {
    background-color: #fff;
    border: none;
    border-radius: 12px;
    color: #222;
    cursor: pointer;
    line-height: 100%;
    outline: none;
    overflow: hidden;
    padding: 16px 30.5px;
    position: relative;
    transition: .2s;
}

.k_cart_trainer_export_button:before {
    background-color: #ed6f2d;
    border: 1px solid #ed6f2d;
    content: "";
    height: 120%;
    left: 0;
    position: absolute;
    top: -10%;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: .3s ease;
    width: 120%;
    z-index: 0;
}

.k_cart_trainer_export_button span {
    transition: .2s ease;
    z-index: 0;
}

.k_cart_trainer_export_button_border {
    border: 1px solid #ed6f2d;
    border-radius: 12px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.k_cart_trainer_export_button:hover {
    color: #fff;
}

.k_cart_trainer_export_button:hover:before {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.k_cart_trainer_export.feature-empty {
    opacity: .5;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}

.k_one {
    display: none;
}

@media screen and (max-width: 1599px) {
    .single_page_body_content_inner_top .k_cart_trainer_export {
        display: none !important;
    }

    .k_mobile .k_cart_trainer_export {
        display: flex;
    }
}

@media screen and (max-width: 1280px) {
    .k_cart_trainer_export {
        display: flex;
    }
}

@media screen and (max-width: 992px) {
    .k_cart_trainer_export {
        border-radius: 12px;
        display: none;
    }

    .k_cart_trainer_export_box_title {
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
    }

    .k_cart_trainer_export_box_desc {
        font-size: 12px;
        line-height: 15px;
        color: grey;
    }

    .k_cart_trainer_export_button {
        border-radius: 6px;
        font-size: 12px;
        line-height: 15px;
        padding: 9px 16px;
    }

    .k_cart_trainer_export {
        padding: 12.5px 20px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 480px) {
    .k_two {
        display: none;
    }

    .k_one {
        display: block;
    }
}

@media screen and (max-width: 450px) {
    .k_cart_trainer_export_box_title {
        font-size: 14px;
        margin-bottom: 4px;
    }

    .k_cart_trainer_export_box_desc {
        font-size: 12px;
    }
}