.header_mobile{
    display: none;
    background: #fff;
    border-radius: 12px 12px 0 0;
    bottom: 0;
    box-shadow: 0 -4px 30px rgba(25, 30, 44, .1);
    height: 76px;
    left: 0;
    padding: 12px;
    position: fixed;
    width: 100%;
    z-index: 9999999999;
}

.header_mobile_list{
    align-items: center;
    display: flex;
    gap: 45px;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
}

.header_mobile_list_one{
    list-style-type: none;
    margin: 0;
}

.header_mobile_list_one_link{
    align-items: center;
    color: #222;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 5px;
    text-decoration: none;
}

.header_mobile_list_one_link_icon{
    height: 30px;
    width: 30px;
}

.header_mobile_list_one_link_profile_premium_icon {
    background-size: cover;
    bottom: 1px;
    height: 12px !important;
    min-width: 10.5px !important;
    position: absolute;
    right: -4px;
    width: 10.5px !important;
    z-index: 9999999;
    margin-left: 5px;
    display: block;
}

.header_mobile_list_one_link_profile_premium_icon img {
    width: 100%;
    height: 100%;
}

.header_mobile_list_one_link_profile_wrapper {
    border-radius: 100%;
    height: 24px;
    object-fit: cover;
    overflow: hidden;
    width: 24px;
}

.header_mobile_list_one_link_profile_wrapper img {
    margin-top: 6px;
    width: 101%;
}

.header_mobile_list_one_link_profile {
    overflow: visible;
    position: relative;
    background: #e9e9eb;
    border-radius: 100%;
    height: 24px;
    object-fit: cover;
    width: 24px;
}

@media screen and (max-width: 992px){
    .header_mobile{
        display: block;
    }
}

@media screen and (max-width: 450px){
    .header_mobile_list_one_link_icon {
        width: 24px;
        height: 24px;
    }

    .header_mobile_list_one_link.style_1_caption_12_500 {
        font-size: 9px;
        gap: 5px;
    }

    .header_mobile {
        padding: 12px 30px;
    }
}

@media screen and (max-width: 390px) {
    .header_mobile_list {
        gap: 0px;
        justify-content: space-between;
    }
}