/* Таблица стилей сортировки для фильтра */

.filter_type_sort {
    margin-left: 20px;
}

.filter_type_sort_select_title {
    color: black;
}

.filter_type_sort_select {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-evenly;
    padding: 12px 12px 12px 13px;
    height: 48px;
    border-radius: 12px;
}

label.filter_type_sort_body_form_label:last-child {
    margin-bottom: -16px;
}

.filter_type_sort_select_title {
    margin-right: 42px;
}

.filter_type_sort_select_arrow.style_1_arrow {
    right: -1px;
    position: relative;
    top: 1px;
    transition: 0.3s;
}

.filter_type_sort_body {
    display: none;
}

.filter_type_sort_body.open {
    display: flex;
}

.filter_type_sort {
    position: relative;
}

.filter_type_sort_body {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    flex-direction: column;
    gap: 20px;
    height: -webkit-max-content;
    height: max-content;
    right: 0;
    padding: 26px;
    position: absolute;
    top: 58px;
    z-index: 2;
}

.filter_type_sort_body h3 {
    margin: 0;
    padding: 0;
}

form.filter_type_sort_body_form.k_coach_sort_pc {
    display: flex;
    flex-direction: column;
}

label.filter_type_sort_body_form_label {
    align-items: center;
    border-top: 1px solid #eeeeef;
    color: #222;
    cursor: pointer;
    display: flex;
    height: 63px;
    justify-content: space-between;
    line-height: 100%;
    width: 236px;
}

input.filter_type_sort_body_form_radio {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
    z-index: -1;
}

span.filter_type_sort_body_form_radio_custom {
    border-radius: 50%;
    height: 31px;
    width: 31px;
    background: #e9e9eb;
    display: inline-block;
    position: relative;
}

span.filter_type_sort_body_form_radio_custom:before {
    background: #fff;
    border: 8px solid #ed6f2d;
    box-sizing: border-box;
    content: "";
    display: block;
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 31px;
    width: 31px;
}

.filter_type_sort_body_form_radio:checked+.filter_type_sort_body_form_radio_custom:before {
    display: block;
}

.filter_type_sort_select.style_1_interactive_button_link.open {
    border: 1px solid #ed6f2d;
}

.filter_type_sort_select.style_1_interactive_button_link.open .filter_type_sort_select_arrow.style_1_arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.filter_type_sort_select_title {
    text-align: left;
}

@media screen and (max-width: 880px){
    .filter_type_sort_body {
        left: 0;
        right: auto;
    }

    .filter_type_sort_select_title {
        font-size: 14px;
        white-space: nowrap;
        margin-right: 0px;
    }

    .filter_type_sort {
        width: -webkit-fill-available;
    }

    .filter_type_sort_select.style_1_interactive_button_link {
        justify-content: space-between;
        width: -webkit-fill-available;
    }

    .filter_type_sort_select.style_1_interactive_button_link.open .filter_type_sort_select_arrow.style_1_arrow {
        transform: rotate(180deg);
        top: -2px;
    }
}

@media screen and (max-width: 480px){
    .filter_type_sort_body.open {
        position: fixed;
        bottom: 68px;
        left: 0;
        box-shadow: none;
        right: 0;
        width: 100%;
        height: fit-content;
        top: auto;
        border-radius: 15px 15px 0 0;
        z-index: 10;
        padding-bottom: 34px;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
    }

    label.filter_type_sort_body_form_label {
        height: 64px;
    }

    .filter_type_sort_body h3 {
        margin-top: -25px;
        margin-bottom: -2px;
    }

    .filter_type_sort_body.open:before {
        box-shadow: 0px -4px 30px 0px #191E2C1A;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 83px;
        bottom: -75px;
        border-radius: 12px;
        pointer-events: none;
    }

    .filter_type_sort_body.open+.filter_type_burger_menu_body_bg {
        background: rgba(21, 21, 23, .5);
        display: block;
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 75px;
        z-index: 9;
    }

    label.filter_type_sort_body_form_label {
        width: auto;
    }

    form.filter_type_sort_body_form.k_coach_sort_pc {
        width: 100%;
        justify-content: space-around;
    }
}