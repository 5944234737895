.level_or_attack {
    border: 1px solid #ed2d2d;
    border-radius: 900px;
    padding: 7px 0px !important;
    width: 78px;
    color: #ed2d2d;
}

.level_or_attack_type_attack_2, .level_or_attack_type_level_2{
    border: 1px solid #BB2DED;
    padding: 8px 12px;
    color: #BB2DED;
}

.level_or_attack_type_attack_1, .level_or_attack_type_level_1{
    border: 1px solid #45D221;
    padding: 8px 12px;
    color: #45D221;
}