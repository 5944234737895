/* Старые стили */

.shop {
    position: relative;
    z-index: 2;
}
.shop__list {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 50px 16px;
    margin-bottom: 80px;
}

.pokemon__title{
    margin-top: 37px;
    margin-bottom: 60px;
}

.shop__item {
    list-style-type: none;
    background: #ffffff;
    border: 2px solid #ffffff;
    backdrop-filter: blur(50px);
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.shop__item:hover {
    border: 2px solid #ed6f2d;
    box-shadow: 0px 4px 50px rgba(255, 92, 0, 0.3);
    backdrop-filter: blur(50px);
    transition: all 0.3s ease;
}

.shop__img {
    max-width: 228px;
    height: 200px;
    margin-bottom: 20px;
}

.shop__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
}

.shop__price {
    margin: 0;
    margin-bottom: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #222222;
}

.shop__button {
    cursor: pointer;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #ed6f2d;
    border-radius: 12px;
    transition: all 0.3s ease;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    width: 100%;
}

.shop__button:hover, .shop__button:focus-visible {
    background-color: #ed6f2d;
    color: #fff;
}

li.shop__item.feature-empty.fix_block_cursor {
    opacity: .5;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}

@media screen and (max-width: 1919px) {
    .shop__list {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 1599px) {
    .shop__list {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 992px) {
    .shop__list {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px 16px;
        margin-bottom: 90px;
    }
    .shop__item {
        padding: 20px;
    }
    .shop__button {
        padding: 10px;
    }
}

@media screen and (max-width: 768px) {
    .shop {
        padding: 0;
    }
    .shop__list {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
    .shop__title {
        font-size: 14px;
    }
    .shop__price {
        font-size: 20px;
    }
}

@media screen and (max-width: 450px) {
    .shop__list {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }
    .shop__item {
        padding: 12px;
        border-radius: 12px;
    }
    .shop__img {
        height: 110px;
        margin-bottom: 12px;
    }
    .shop__title {
        margin-bottom: 6px;
        font-size: 12px;
        line-height: 15px;
    }
    .shop__price {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 17px;
    }
    .shop__button {
        border-radius: 6px;
        font-size: 12px;
        line-height: 15px;
    }
    .shop_page {
        padding: 0 12px;
    }
}

@media screen and (max-width: 320px) {
    .shop__title {
        font-size: 10px;
    }
}


/* Новые стили */


.premium_page {
    flex: 1 1;
    text-align: left;
}