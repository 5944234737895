/* Старые стили */
.profile {
    position: relative;
    z-index: 100;
}

.profile__email-f {
    padding-bottom: 22px;
}

.profile__container {
    border-bottom: 1px solid #eeeeef;
}

span.k_skidka_premium {
    display: block;
    position: absolute;
    right: 4.5px;
    top: 27px;
    padding: 2px 12px 0.07px;
    border-radius: 16px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.94px;
    text-align: left;
    color: black;
    opacity: 0.65;
}

input[type= "number"]::-webkit-inner-spin-button, input[type= "number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.k_pre_title_premium {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    margin-bottom: 16px;
}

.k_info_premium {
    margin-bottom: 32px;
}

.k_list_b_premium ul li::marker {
    font-size: 12px;
}

.k_list_b_premium ul li::marker {
    font-size: 12px;
}

.k_list_b_premium ul li:before {
    content: '';
    list-style-type: disc;
    display: list-item;
    float: left;
    width: 1px;
    margin: 0 -4px 0 10px;
    font-size: 12px;

    opacity: 0;
}

.k_page_main_premium .k_premium_buy_form {
    border-bottom: none !important;
}

.k_info_line_premium {
    margin-bottom: 10px;
    display: flex;
}

.k_info_line_premium img {
    margin-right: 10px;
}

.k_info_line_premium span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
}

.k_pre_title_premium.skidka_button {
    font-size: 14px;
    line-height: 16.8px;
    display: flex;
    cursor: pointer;
}

.skidka_button_icon {
    margin-left: 5px;
    transform: rotate(90deg) scaleX(0.5);
    font-size: 18px;
    transition: 0.7s;
}

.k_flex_premium + button {
    float: none !important;
    width: 100%;
    margin-top: 0px !important;
}

.k_flex_premium {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-top: 16px;
    margin-top: 24px;
    border-top: 1px solid #EEEEEF;
    align-items: center;
}

.k_flex_premium + div {
    margin-top: 2px;
}

.k_active_premium {
    text-align: center;
    margin-bottom: 31px;
}

.k_active_premium img {
    width: 48px;
    height: 48px;
    margin-bottom: 20.02px;
}

.k_input_premium{
    margin-bottom: 7px !important;
}

.k_price_premium {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    margin-left: auto;
}

span.k_input_premium_desc {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    opacity: 0.65;
}

@media screen and (min-width: 768px) {
    .premium_fix_top {
        margin-top: -47px;
    }
}

.k_title_premium {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    text-align: center;
    margin-bottom: 10px;
}

.k_txt_premium {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    color: #808080;
    display: flex;
    justify-content: center;
}

.k_txt_premium .history-info-duration-num {
    margin-left: 3.5px;
}

.k_list_b_premium ul {
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    margin: 0px;
    padding-left: 5px;
}

.k_list_b_premium ul li {
    margin-bottom: 10px;
    margin-left: 10px;
}

.k_list_b_premium {
    margin-top: 1px;
    position: relative;
    display: inline-block;
    margin-bottom: 21px;
}

.k_pre_title_premium.skidka_button.active .skidka_button_icon {
    transform: rotate(270deg) scaleX(0.5);
}


.k_profile_title_left {
    max-width: 200px;
    word-break: break-all;
}

.profile__title {
    margin-top: 0;
    margin-bottom: 45px;
    font-family: "Pokemon Classic";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    color: #222222;
}

.profile__label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-top: 0;
    margin-bottom: 10px;
}

.profile__subtitle {
    color: #808080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mb-16 {
    margin-top: 0;
    margin-bottom: 16px;
}

.p-22 {
    padding: 22px 0;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-center {
    text-align: center;
}

.k_buy_premium{
    opacity: 0.5;
}

.k_buy_premium.active{
    opacity: 1;
}

.profile__button:hover{
    background: #ed6f2d !important;
    color: #fff !important;
}

.profile__button:hover, div:hover > .profile__button {
    background: #ed6f2d !important;
    color: #fff !important;
    cursor: pointer;
}

.profile__button:hover {
    background: #fff !important;
    color: #ed6f2d !important;
}

.k_buy_premium.active:hover {
    background: #fff !important;
    color: #ed6f2d !important;
}

.k_txt_premium {
    margin-top: -1px;
    left: 2.5px;
    position: relative;
}

.profile__button-right {
    margin-left: auto;
}

.k_txt_premium.k_fix {
    margin-top: -23px;
    margin-bottom: 31px;
    left: 0px;
}

.k_input_days {
    border-radius: 12px;
    min-height: 56px;
    height: 56px;
    margin-bottom: 1px;
}

.k_page_main_premium {
    padding-bottom: 39px;
}

.k_cansel_premium, .k_buy_premium, .k_cansel_go_premium, .k_cansel_back_premium, .k_ok_battles_premium, .k_re_subscribe_back_premium {
    width: 100% !important;
    height: 56px !important;
}

.k_cansel_premium, .k_buy_premium, .k_cansel_go_premium, .k_cansel_back_premium, .k_ok_battles_premium, .k_re_subscribe_back_premium {
    border-radius: 12px !important;
    font-size: 14px !important;
}

.k_cansel_back_premium {
    margin-bottom: 16px;
}

.k_pre_title_premium.k_big_font_premium {
    margin-bottom: 32px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    text-align: center;
}

@media screen and (max-width: 460px){
    .button_to_down {
        bottom: 24px;
        width: -webkit-fill-available;
        left: 0;
        right: 0;
        position: absolute;
        margin: 0 12px;
    }

    .premium_fix_top > div {
        min-height: 100vh !important;
    }
}


.k_cansel_premium, .k_cansel_go_premium {
    background: #F0F0F0 !important;
    color: black !important;
    border: 1px solid #F0F0F0 !important;
    height: 56px !important;
}

.profile__button-light {
    border: 1px solid #ed6f2d;
    background: transparent;
    color: #222222;
    min-width: 145px;
}

.profile__button-light:hover {
    background: #ed6f2d;
    color: #fff;
}

.profile__title-grey {
    color: #808080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 10px;
}

.profile__text {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #222;
}

.profile__text-grey {
    color: #808080;
    opacity: 0.6;
}

.profile__input.active {
    color: #222222;
    opacity: 0.6;
}

.profile__back-button {
    width: fit-content;
    margin-left: 40px;
    background: #ffffff;
    border-radius: 15px;
    padding: 10px 18px 10px 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    border: 1px solid #ffffff;
    transition: border 0.3s ease-in;
    position: relative;
    z-index: 10;
}

.profile__back-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    margin: 0;
}

.profile__back-button:hover {
    border: 1px solid #ed6f2d;
}

.profile-mobile {
    display: none;
}

.profile__title-mobile {
    color: #222222;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

form.auth__form.profile__email-f.profile__container.k_profile_email_register {
    position: relative;
    padding-bottom: 30px;
}

.login__content {
    background: #fff;
    border-radius: 15px;
    margin: 0 auto 20px;
    max-width: 420px;
    padding: 40px 30px;
    position: relative;
    width: 100%;
    z-index: 10;
}

@media screen and (max-width: 992px) {

    .back-button {
        padding: 4px;
        border-radius: 100%;
    }
    .back-button__text {
        display: none;
    }

    .profile__back-button {
        margin-top: 40px;
    }
}
@media screen and (max-width: 768px) {
    .k_premium_buy_form{
        position: static !important;
    }

    button.profile__button.profile__button-right.k_send_mail_confirm {
        float: right;
        margin-top: 0px;
    }
    .back-button {
        position: absolute;
        top: 0;
        left: 0;
    }
    .k_profile_email_register, .profile__container {
        height: fit-content !important;
    }
    .profile__button {
        min-width: 144px;
    }
    .k_profile_email_ok + div {
        border-bottom: 1px solid #eeeeef;
        /*border-top: 1px solid #eeeeef;*/
        margin-top: 22px;
    }
}


@media screen and (max-width: 460px) {
    .profile {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }

    .profile {
        padding-bottom: 0px !important;
    }

    .profile-mobile {
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(25, 30, 44, 0.1);
        border-radius: 0px 0px 12px 12px;
        padding: 12px 12px 11px !important;
    }

    .profile-mobile-wrapper {
        padding: 0px 12px 0px !important;
        overflow-y: scroll;
        padding-bottom: 39px;
    }

    .k_list_b_premium ul li {
        margin-bottom: 10.2px;
    }

    input.auth__input.profile__input.k_input_days {
        padding: 27px 16px 10px 16px;
    }

    .auth__label {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        top: 19px;
        left: 17px;
    }
    .k_input_premium {
        margin-bottom: 3px !important;
    }
    .k_flex_premium {
        margin-top: 23px;
    }

    span.k_skidka_premium {
        right: 5px;
    }

    .k_info_premium {
        margin-bottom: 32px;
    }
    .profile {
        border-radius: 0px;
        height: 100vh;
    }

    .profile__button {
        font-size: 14px;
    }

    .profile__wrap {
        border-radius: 0;
        min-height: 100vh;
    }

    .profile__back-button,
    .profile__title {
        display: none;
    }

    .profile-mobile {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }


    .profile__back-button-mobile {
        border: 1px solid #ed6f2d;
        border-radius: 100%;
        width: 38px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile__title-mobile {
        text-align: center;
        width: 100%;
    }


}

.auth {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
}
.df.register__df input#password + label + span + span.auth__error {
    max-width: 150px;
}
.auth-full {
    height: calc(100vh - 50px - 80px);
}
.auth__content {
    background: #ffffff;
    border-radius: 15px;
    padding: 40px 30px;
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    position: relative;
    z-index: 10;
}
.auth__content-mini {
    padding: 30px;
}
.auth__back {
    display: none;
    width: 34px;
    height: 34px;
    border: 1px solid #ed6f2d;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: -10px;
    left: 0;
}
.auth__mobile {
    width: 100%;
    position: relative;
}

.auth__title-h2 {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    padding-bottom: 10px;
}
.auth__title {
    margin-top: 0;
    margin-bottom: 45px;
    font-family: "Pokemon Classic";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 42px;
    color: #222222;
}
.auth__subtitle {
    margin-top: 0;
    margin-bottom: 25px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #808080;
}

.auth__form {
    display: flex;
    flex-direction: column;
}

.k_pre_title_premium_fix{
    margin-bottom: -12px;
}

.auth__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
    margin: 0;
    margin-bottom: 15px;
}
.auth__text_small {
    font-size: 12px;
}
.auth__socials {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 45px;
}
.auth__social {
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 6px;
    padding: 12px 17px;
    transition: all 0.3s ease-in;

    display: flex;
    align-items: center;
    justify-content: center;
}
.auth__social:hover {
    border: 1px solid #ed6f2d;
}
.auth__social-icon {
    width: 24px;
    height: 24px;
}

.auth__wrap {
    position: relative;
    margin-bottom: 25px;
}

.k_form_f_pass, .k_form_f_pass_1, .k_form_f_pass_2, .k_form_re_pass_1, .k_form_re_pass_2 {
    padding-right: 40px !important;
}

.auth__input {
    display: block;
    width: 100%;
    border: 1px solid #f8f8f8;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 27px 16px 10px 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: border 0.3s ease-in;
    min-height: 57px;
    height: 57px;
    outline: none;
}

.auth__input-pass[aria-invalid="true"] {
    margin-bottom: 10px;
}


.auth__input[aria-invalid="true"] {
    border: 1px solid #e75149;
}

.auth__input:hover,
.auth__input:active {
    border: 1px solid #222222;
}

.auth__input:focus {
    color: #222222;
}

.auth__label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
    position: absolute;
    top: 19px;
    left: 17px;
    transition: all 0.3s ease-in;
    pointer-events: none;
}

.auth__input:not(:placeholder-shown) + label, .auth__input:focus + label, .auth__input[aria-invalid="true"] + label, .auth__input:-webkit-autofill + label {
    top: 9px;
    font-size: 12px;
}

.auth__input-icons,
.auth__input-icons:focus {
    padding-right: 10px;
}

.auth__input-icon {
    display: none;
    position: absolute;
    top: 20px;
    right: 15px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.auth__input-icon {
    background-image: url(../../assets/icons/form/form_eye_icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: none;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 20px;
    width: 20px;
}

.auth__input-icon.view-pass {
    background-image: url(../../assets/icons/form/form_eye_off_icon.svg);
    display: block;
}

.auth__input-icon.view-pass {
    display: block;
}
.auth__input-icon.show {
    display: block;
}

.auth__error {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #e75149;
    margin-top: 10px;
    margin-bottom: 2px;
    position: relative;
}

.auth__restore {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #808080;
    text-decoration: none;
    margin-bottom: 45px;
    transition: color 0.3s ease-in;
    width: max-content;
}
a.auth__restore {
    margin-top: -4px;
}
.auth__restore:hover {
    color: #222222;
}
.auth__button {
    border: 1px solid #ed6f2d;
    background: #ed6f2d;
    border-radius: 6px;
    padding: 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 16px;
    transition: all 0.3s ease-in;
}
.auth__button:hover {
    border: 1px solid #ed6f2d;
    background-color: transparent;
    color: #ed6f2d;
}
.auth__registr {
    text-decoration: none;
    text-align: center;
    margin-bottom: 0;
    transition: color 0.3s ease-in;
    width: max-content;
    margin: 0 auto;
}

.auth__registr:hover {
    color: #ed6f2d;
}
.auth__wrapper {
    display: block;
    text-align: center;
    width: 100%;
    z-index: 5;
    margin-top: auto;
}
.auth__wrapper-mini {
    bottom: 20px;
}
.auth__logo {
    width: 100px;
    height: 29px;
}
.auth__version {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    opacity: 0.5;
}
.auth__confirmation {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #808080;
    margin: 0;
}
.auth__confirmation-mb {
    margin-bottom: 45px;
}
.auth__confirmation-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 45px;
}
.auth__confirmation-wrapper {
    width: 320px;
}
.auth__confirmation-btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #ed6f2d;
    text-decoration: none;
}
.df {
    display: flex;
    align-items: center;
    gap: 15px;
}

.wrapper-mb {
    margin-bottom: 35px;
}

p.auth__text.auth__text_small {
    margin-bottom: 25px;
}

.k_margin_top_15{
    margin-top: 15px;
}

.k_margin_bottom_16{
    margin-bottom: 16px;
}

.k_margin_bottom_16 p.auth__title-h2 {
    padding: 0;
}

.k_f_error {
    border: 1px solid #E75149 !important;
}



@media screen and (max-width: 460px) {
    .k_premium_buy_form {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .k_margin_top_15{
        margin-top: 0px;
    }
    p.auth__confirmation {
        font-size: 12px;
        width: 336px;
    }
    p.auth__confirmation + p.auth__confirmation {
        font-size: 14px;
    }
    .auth__confirmation-wrapper {
        gap: 20px;
    }
    p.auth__text.auth__text_small {
        margin-bottom: 20px;
    }
    .hidden {
        display: none;
        opacity: 0;
    }
    .auth {
        padding: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .auth-full {
        height: 100dvh;
    }
    .auth__form {
        height: 100%;
        display: contents;
    }
    .auth__mobile {
        margin-bottom: 24px;
    }
    .auth__mobile-top {
        order: -1;
    }
    .auth__back {
        display: block;
    }
    .auth__content {
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 25px 12px;
        display: flex;
        flex-direction: column;
        border-radius: 0;
    }
    .auth__content {
        min-height: 100vh;
    }
    .auth__wrapper {
        display: none;
        opacity: 0;
    }
    .auth__title {
        display: none;
    }
    .auth__title-h2 {
        text-align: center;
        padding-bottom: 25px;
    }
    .auth__socials {
        margin-bottom: 32px;
    }

    .auth__wrap {
        margin-bottom: 16px;
        position: relative;
        width: 100%;
    }

    .auth__input {
        border-radius: 6px;
    }

    .auth__label {
        font-size: 12px;
        line-height: 15px;
        top: 20px;
        left: 16px;
    }

    .auth__input:focus {
        padding: 25px 26px 16px 16px;
    }
    .auth__input:focus + label {
        transform: translateY(0px);
    }

    .auth__input:focus ~ .auth__input-icon {
        top: 20px;
        right: 16px;
    }

    .auth__restore {
        margin-bottom: 32px;
    }
    .auth__button {
        border-radius: 6px;
        padding: 20px;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
        margin-top: auto;
    }
    .auth__registr {
        font-size: 14px;
        line-height: 120%;
    }
    .reg_form_button {
        display: none !important;
    }

    .autn__button-hidden {
        display: none;
    }
    .auth__confirmation-btn {
        width: 100%;
        background: #ED6F2D;
        border-radius: 6px;
        text-align: center;
        color: #FFFFFF;
        padding: 20px;
        margin-top: auto;
    }
}


/* Новые стили */


.premium_page {
    flex: 1 1;
    text-align: left;
}

input.auth__input.profile__input.k_input_days {
    border-radius: 12px;
}

@media screen and (max-width: 992px){
    .k_pre_title_premium_fix {
        margin-bottom: 0px;
    }

    h2.profile__title-mobile {
        left: -15px;
        position: relative;
    }

    .k_input_days+label.auth__label {
        font-size: 14px;
    }

    span.k_input_premium_desc {
        color: #808080;
        margin-top: 4px;
    }

    .premium_page .top_back_button {
        border-radius: 15px;
        padding: 9px 17.11px 9px 9.11px;
        margin-left: 40px;
        width: 100%;
    }

    .premium_page .top_back_button .top_back_button_text{
        display: block;
    }

    .premium_page {
        margin-top: 40px;
    }
}

@media screen and (max-width: 768px){
    .premium_page .top_back_button {
        margin-top: 40px;
    }

    section.auth.profile.premium_fix_top {
        margin: 80px 0 20px;
    }
}

@media screen and (max-width: 460px){
    section.auth.profile.premium_fix_top {
        margin: 0px;
    }

    .premium_page .top_back_button {
        display: none;
    }

    .premium_page {
        margin: 0px;
    }

    .login__content {
        margin: 0px !important;
        width: 100%;
        max-width: 100%;
    }
}