.style_1_popup_white_link_standart {
    color: grey;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    transition: color .3s ease-in;
    width: max-content;
    margin-top: 17px;
    display: block;
}

.style_1_popup_white_link_left {
    margin-right: auto;
    margin-left: 0px;
    margin-bottom: 46px;
}

.style_fix_1{
    margin-bottom: 1px;
    padding-top: 1px;
}