.auth_content_socials {
    align-items: center;
    display: flex;
    gap: 12px;
    margin-bottom: 45px;
}

a.auth_content_social {
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 12px;
    justify-content: center;
    padding: 14px 15px 16px 15px;
    transition: all .3s ease-in;
    align-items: center;
    display: flex;
}

.auth_content_social_icon {
    height: 24px;
    width: 24px;
}

p.auth_content_text {
    line-height: 16.94px;
}

a.auth_content_social:hover{
    border: 1px solid #ED6F2D;
}

@media screen and (max-width: 480px){
    .auth_content_socials {
        margin-bottom: 31px;
    }
}