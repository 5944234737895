.top_back_button {
    align-items: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 15px;
    display: flex;
    height: -webkit-max-content;
    height: max-content;
    justify-content: center;
    padding: 9px 17.11px 9px 9.11px;
    position: relative;
    text-decoration: none;
    transition: border .3s ease-in;
    z-index: 10;
    max-width: 102px;
}

img.top_back_button_icon {
    height: 26px;
    object-fit: cover;
    width: 26px;
}

span.top_back_button_text {
    color: black;
}

.top_back_button:hover{
    border: 1px solid #ED6F2D;
}

@media screen and (max-width: 992px) {
    .top_back_button {
        border-radius: 100%;
        padding: 4px;
    }

    .top_back_button_text {
        display: none;
    }

    .top_back_button {
        left: 1px;
    }
}

@media screen and (max-width: 768px) {
    .top_back_button {
        left: 0;
        position: absolute;
        top: 0;
    }
}