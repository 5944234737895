.pokemons_list_card {
    flex: calc((100% - 16px) / 5) 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    backdrop-filter: blur(50px);
    border-radius: 40px;
    padding: 30px;
    cursor: pointer;
    border: 3px solid white;
    transition: 0.3s;
    max-height: 365px;
}

.pokemons_list_card_top_img img {
    width: 100%;
    max-width: 228px;
    max-height: 200px;
}

.pokemons_list_card_center_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.pokemons_list_card_top {
    margin-bottom: 25px;
}

.pokemons_list_card_center {
    display: flex;
    gap: 6px;
    margin-bottom: 16px;
}

.pokemons_list_card_bottom {
    display: flex;
    gap: 12px;
}

.copy_number {
    display: flex;
    align-items: center;
    gap: 8px;
}

.copy_number_button {
    background-image: url(../../../assets/icons/base_copy_icon.svg);
    background-repeat: no-repeat;
    background-color: initial;
    height: 20px;
    width: 20px;
    border: none;
    cursor: pointer;
}

.pokemon_dead {
    background: #FFFFFF;
    opacity: 0.5;
    backdrop-filter: blur(50px);
    border-radius: 40px;
}

.pokemon_dead button.copy_number_button {
    display: none;
}

.pokemon_dead .pokemons_list_card_top_img {
    filter: grayscale(100%);
}

.pokemons_list_card.max_attack {
    border: 3px solid #F7C615;
    position: relative;
}

.pokemons_list_card:hover {
    border: 3px solid #ed6f2d;
    box-shadow: 0 4px 50px rgba(255, 92, 0, .3);
}

.pokemons_list_card.max_attack:before {
    content: '';
    display: block;
    background: white;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    position: absolute;
    z-index: -10;
    border-radius: 30px;
    border: 3px solid #F7C615;
}

.pokemons_list_card_center_name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pokemons_list_card_bottom .icon_pokeball,
.pokemons_list_card_bottom .icon_skull{
    height: 30px;
}

.trainers_list_card_right_top_name {
    height: 27px;
}

.pokemon_dead .icon_max_attack {
    filter: grayscale(1);
}

@media screen and (min-width: 1920px){
    .pokemons_list_card {
        flex: calc((100% - 16px) / 7) 1;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1919px){
    .pokemons_list_card {
        flex: calc((100% - 16px) / 6) 1;
    }
}

@media screen and (max-width: 880px){
    .pokemons_list_card {
        max-width: calc(33vw - 30px);
    }
}

@media screen and (max-width: 768px){
    .pokemons_list_card_bottom .icon_skull, .pokemons_list_card_bottom .icon_skull img {
        height: 24px;
    }

    .pokemons_list_card_top {
        margin-bottom: 12px;
    }

    .pokemons_list_card_center_name {
        line-height: 19.36px;
    }

    .pokemons_list_card_center {
        margin-bottom: 8px;
    }

    .pokemons_list_card .level_or_attack {
        padding: 4px 0px !important;
        font-size: 12px;
        line-height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pokemons_list_card_bottom .icon_pokeball img, .pokemons_list_card_bottom .icon_pokeball {
        width: 24px;
        height: 24px;
    }

    .pokemons_list_card_bottom {
        gap: 4px;
    }

    .icon_max_attack img {
        width: 18px;
        height: 18px;
    }

    .pokemons_list_card {
        padding: 15px 17px;
        border-radius: 12px;
        max-width: calc(50vw - 30px);
    }

    .pokemons_list_card.max_attack:before {
        left: 6px;
        top: 6px;
        right: 6px;
        bottom: 6px;
        border-radius: 6px;
        border: 2px solid #F7C615;
    }

    .pokemons_list_card.max_attack {
        border: 2px solid #F7C615;
    }
}

@media screen and (max-width: 580px){
    .pokemons_list_card_center_name {
        max-width: 36vw;
    }
}

@media screen and (max-width: 480px){
    .pokemons_list_card_bottom .copy_number {
        display: none;
    }

    .pokemons_list_card_top_img img {
        height: 106px;
        max-width: 133px;
    }

    .pokemons_list_card {
        max-width: calc(50vw - 18px);
    }

    .pokemons_list_card_center_name {
        max-width: 25vw;
    }
}