.achievements_icon {
    float: left;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 4px;
    filter: grayscale(1);
    opacity: 0.5;
}

.achievements_icon.active {
    opacity: 1;
    filter: none;
}

.achievements {
    position: relative;
    border-top: 1px solid #EEEEEF;
    padding-top: 12px;
    margin-top: 20px;
    display: flex;
    margin-bottom: -8px;
}

.achievements_popup {
    position: absolute;
    filter: drop-shadow(0px 6px 12px rgba(0, 16, 61, 0.06)) drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 2px rgba(0, 16, 61, 0.06));
    top: 76px;
    z-index: -1;
    transition: 0.3s;
    opacity: 0;
}

.achievements_popup_top {
    position: absolute;
    z-index: 0;
    top: -15px;
    filter: drop-shadow(0px 6px 12px rgba(0, 16, 61, 0.06)) drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 2px rgba(0, 16, 61, 0.06));
}

.achievements_popup_content {
    padding: 10px 12px 10px 12px;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    width: 160px;
}

.achievements_popup_content_title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    margin-bottom: 4px;
}

.achievements_popup_content_txt {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #808080;
}

.achievements_icon img {
    width: 100%;
    height: 100%;
}

.achievements_off {
    height: 0px;
    margin-top: -12px;
}

.achievements_popup.open {
    opacity: 1;
    z-index: 9999;
    transition: 0.3s;
}

@media screen and (max-width: 450px) {
    .achievements {
        position: relative;
        border-top: 1px solid #EEEEEF;
        padding-top: 12px;
        margin-top: 20px;
        display: flex;
        margin-bottom: -8px;
    }

    .achievements_icon {
        width: 32px;
        height: 32px;
    }

    .achievements_icon img {
        width: 32px !important;
        height: 32px !important;
    }

    .achievements_icon.five_defends-icon img, .achievements_icon.five_battles-icon img {
        width: 22px !important;
        height: 22px !important;
    }

    .achievements_popup_top {
        position: absolute;
        z-index: 0;
        top: -15px;
        filter: drop-shadow(0px 6px 12px rgba(0, 16, 61, 0.06)) drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 2px rgba(0, 16, 61, 0.06));
        transition: 0.6s;
    }
}