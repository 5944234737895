.style_1_popup_white_title2 {
    margin-bottom: 10px;
    position: relative;
}

p.register_subtitle a {
    color: #ED6F2D;
    text-decoration: none;
}

a.style_1_popup_white_title_prev {
    display: none;
}


@media screen and (max-width: 460px) {
    a.style_1_popup_white_title_prev {
        background-image: url(../../../assets/icons/base_arrow_left.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        border: 1px solid #ed6f2d;
        border-radius: 100%;
        height: 34px;
        left: 12px;
        position: absolute;
        top: 15px;
        width: 34px;
        display: block;
    }

    .style_1_popup_white_title2 {
        padding: 25px 12px;
    }

    p.register_title_h2 {
        text-align: center;
    }

    .style_1_popup_white_title2 {
        padding: 25px 12px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(25, 30, 44, 0.1);
        border-radius: 0px 0px 12px 12px;
        margin: 0 -12px;
        margin-bottom: 24px;
    }

    .style_1_popup_white_title2 {
        position: absolute;
        top: 0;
        left: auto;
        right: auto;
        width: 100%;
    }
}