/* Таблица стилей фильтра для страниц "Покемоны" и "Тренеры" */

.filter_body {
    display: flex;
    align-items: flex-end;
    margin-top: 40px;
    margin-bottom: 60px;
    height: 62px;
}

@media screen and (max-width: 880px) {
    .filter_body {
        flex-direction: column;
        align-items: normal;
        height: auto;
        padding: 0 12px;
        margin-bottom: 24px;
    }

    .filter_header.sticky {
        box-shadow: 0px 4px 30px 0px #191E2C1A;
        background: white;
        border-radius: 0px 0px 24px 24px;
        margin: 0 -12px;
        min-width: 100vw;
        position: fixed;
        top: 0px;
        padding: 16px 12px 12px;
    }

    .filter_body.sticky h1.style_1_heading_38_400_pokemon_classic {
        margin-bottom: 68px;
    }

    .filter_header.sticky .filter_type_sort, .filter_header.sticky .right_block > div {
        border: 1px solid #DDDDDD !important;
        border-radius: 12px;
    }

    .filter_header.sticky .right_block > div {
        border-radius: 15px;
    }

    .filter_body>div {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        margin-top: 20px;
        max-width: 100%;
    }

    .filter_body .style_1_heading_38_400_pokemon_classic {
        text-align: left;
    }

    .filter_type_sort {
        margin-left: 0px;
    }

    .right_block {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
    }

    .filter_body {
        z-index: 99999;
    }

}

@media screen and (max-width: 480px){

}