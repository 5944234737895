/* Таблица стилей поиска для фильтра */

.filter_type_search {
    width: 232px;
    display: flex;
    align-items: center;
    float: left;
    margin-right: 8px;
    position: relative;
}

.filter_type_search_icon, .filter_type_search_icon_clear {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter_type_search_field::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    opacity: 0.5;
    color: black;
}

.filter_type_search_field {
    height: 48px;
    padding-left: 45px;
    width: 100%;
    padding-right: 33px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    outline: none;
}

.filter_type_search_icon {
    padding-left: 7px;
    width: 40px;
    height: 40px;
}

.filter_type_search_icon_clear {
    right: 10px;
    top: 14px;
    bottom: 14px;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: 0.3s;
}

.filter_type_search_icon_clear.show {
    opacity: 1;
    display: flex;
}

.filter_type_search_icon_close {
    display: none;
}


@media screen and (max-width: 580px) {
    .filter_type_search_icon_clear{
        display: none !important;
    }

    .filter_type_search_field {
        display: none;
    }

    .filter_type_search {
        width: fit-content;
        margin: 0px;
    }

    .filter_type_search_icon {
        background: white;
        transition: 0.2s;
        border: 1px solid #ffffff;
        border-radius: 15px;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0px;
    }


    .filter_type_sort.close {
        display: none;
    }

    .filter_type_search.mobile_open input {
        display: block;
    }

    .filter_type_search.mobile_open .filter_type_search_icon {
        position: absolute;
        background: none;
        border: none;
    }

    .filter_type_search.mobile_open {
        width: -webkit-fill-available;
    }

    .right_block.mobile_open {
        width: 100%;
        justify-content: space-between;
    }

    .right_block.mobile_open .filter_type_burger_menu {
        display: none;
    }

    .filter_type_search_icon_close.mobile_open {
        transition: 0.2s;
        background: #FFFFFF;
        border: 1px solid #ffffff;
        border-radius: 15px;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        text-decoration: none;
        color: #222222;
        min-width: 50px;
    }
}

@media screen and (max-width: 480px){




}