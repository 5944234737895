section.auth_content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: center;
    margin: 80px 0 50px;
    width: 100%;
}

.auth_mobile_top {
    display: none;
}

h2.auth_mobile_top_title, p.auth_mobile_top_sub {
    color: #fff;
    font-style: normal;
    line-height: 120%;
    text-align: center;
}

p.auth_mobile_top_sub {
    margin: 0 0 24px;
}

h2.auth_mobile_top_title {
    margin: 0 0 12px;
}

.auth_content_body {
    background: #fff;
    border-radius: 15px;
    margin: 0 auto 20px;
    max-width: 420px;
    padding: 40px 30px;
    position: relative;
    width: 100%;
    z-index: 10;
}

.auth_content_title {
    font-style: normal;
    margin-top: 0;
}

p.auth_content_text {
    color: grey;
    margin: 0 0 15px;
    text-align: left;
}

h1.auth_content_title {
    margin-bottom: 45px;
    color: #222;
}

.MuiFormHelperText-root.Mui-error {
    margin-left: 0 !important;
    margin-bottom: 2px !important;
    margin-top: 10px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
}

.MuiFormHelperText-root.Mui-error.k_error_submit_fix{
    margin-top: -38px !important;
    margin-bottom: 44px !important;
}

.MuiFormHelperText-root.Mui-error{
    color: #E75149 !important;
}

.k_form_control {
    margin-bottom: 24px !important;
    position: relative !important;
}

.k_form_control_no_margin_bottom {
    margin-bottom: 0px !important;
}

a.auth_restore {
    color: grey;
    margin-bottom: 45px;
    text-decoration: none;
    transition: color .3s ease-in;
    width: -webkit-max-content;
    width: max-content;
    float: left;
}

a.auth_registr_add {
    color: grey;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    transition: color .3s ease-in;
    width: -webkit-max-content;
    width: max-content;
}

a.base_link:hover {
    color: #ed6f2d !important;
}

.k_button_base_op {
    background: #ed6f2d !important;
    border: 1px solid #ed6f2d !important;
    border-radius: 6px !important;
    color: #fff !important;
    cursor: pointer !important;
    margin-bottom: 16px !important;
    padding: 19px !important;
    transition: all 0.3s ease-in !important;
    box-shadow: none !important;
}

.k_button_base_op:hover {
    background-color: initial !important;
    border: 1px solid #ed6f2d !important;
    color: #ed6f2d !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border-color: white !important;
    outline: none !important;
    transition: 0.3s;
}

.MuiOutlinedInput-root {
    background: #f8f8f8 !important;
    border-radius: 6px !important;
}

.MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #222 !important;
}

.style_1_all_error .MuiOutlinedInput-root {
    border: 1px solid #E75149 !important;
}

.style_1_all_error .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
    outline: none !important;
    border: none !important;
}

.style_1_all_error .MuiOutlinedInput-root fieldset {
    outline: none !important;
    border: none !important;
}


.MuiOutlinedInput-root.Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.MuiInputLabel-root {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-size: 12px;
}

.MuiOutlinedInput-input {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.k_error_submit p.MuiFormHelperText-root {
    margin-bottom: 16px;
}

.k_error_submit {
    margin-top: 0px !important;
}

.style_1_all_error .MuiInputBase-root input {
    outline: none !important;
}

@media screen and (max-width: 460px){
    .style_1_popup_white_in_auth {
        max-height: 500px;
        margin-bottom: 0px !important;
        overflow: scroll;
    }

    .style_1_popup_white_out_auth{
        margin-bottom: 0px !important;
    }

    .k_error_submit p.MuiFormHelperText-root {
        margin-top: -10px !important;
        margin-bottom: 35px !important;
    }

    @media screen and (max-height: 666px){
        .auth_mobile_top {
            display: none !important;
        }
    }

    .k_form_control {
        margin-bottom: 16px !important;
    }

    .MuiFormHelperText-root.Mui-error {
        margin-bottom: 2px !important;
        margin-top: 5px !important;
        letter-spacing: 0px !important;
    }

    section.auth_content {
        background: linear-gradient(42.24deg, #ed6f2d 13.34%, #ffd600 106.42%), #fff;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100dvh;
        padding-top: 50px;
        margin: 0;
        padding: 0;
        justify-content: space-between;
    }

    .auth_mobile_top.k_mobile_visible {
        padding-top: 50px;
    }

    h2.auth_mobile_top_title {
        max-width: 220px;
    }

    .auth_content_body {
        margin: 0px;
    }

    h1.auth_content_title {
        display: none;
    }

    .auth_content_body {
        margin: 0px;
        padding: 25px 12px;
        border-radius: 15px 15px 0 0;
    }
}