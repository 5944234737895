.style_1_base_link_blue {
    align-items: center;
    background-color: inherit;
    border: none;
    color: #5589f1;
    cursor: pointer;
    display: flex;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    margin: 0;
    outline: none;
    padding: 0;
}

.payment_form_card_form_3ds {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.payment_form_card_form_3ds .style_1_base_button_payment_body {
    margin-top: 6px;
}

.style_1_base_link_blue_exit {
    height: 14px;
    position: relative;
    top: -11px;
    width: 68px;
}

.timer_reset {
    margin-top: -15px;
}

.payment_form_3ds_title {
    color: #f8f8f8;
}