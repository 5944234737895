.pokemon_list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
}

.pokemon_list {
    grid-column-gap: 16px;
    grid-row-gap: 60px;
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 0;
    padding-left: 0;
    row-gap: 60px;
    margin-bottom: 80px;
}

@media screen and (max-width: 1919px) {
    .pokemon_list {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 1600px) {
    .pokemon_list {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 1599px) {
    .pokemon_list {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1280px) {
    .pokemon_list {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1279px) {
    .pokemon_list {
        gap: 16px;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .pokemon_list {
        gap: 12px;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 480px) {
    .pokemon_list {
        padding: 0 12px;
    }
}
