.list_trainers_pokemons_page h1 {
    margin: 20px 0 5px 0;
}

span.k_id_image {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    color: #222222;
    margin: 0;
    margin-right: 6px;
    width: 100%;
    text-align: center;
    display: block;
}

.list_trainers_pokemons_page #breadcrumb_nav {
    color: #999;
}

.list_trainers_pokemons_page #container {
    overflow: auto;
    width: 100%;
}

.list_trainers_pokemons_page .gallery {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list_trainers_pokemons_page .gallery li {
    padding: 20px;
    margin: 0;
    float: left;
    position: relative;
    width: 20%;
    height: auto;
    overflow: hidden;
}

.list_trainers_pokemons_page .gallery a {
    text-decoration: none;
}

.list_trainers_pokemons_page .gallery img {
    background: #F0F0F0;
    border: solid 3px #888;
    border-radius: 30px;
    padding: 5px;
    width: 100%;
}

.list_trainers_pokemons_page #page_nav {
    color: #999;
    clear: both;
    text-align: center;
}

.list_trainers_pokemons_page #page_nav a:link, .list_trainers_pokemons_page #page_nav a:visited, .list_trainers_pokemons_page #page_nav a:hover, .list_trainers_pokemons_page #page_nav a:visited:hover {
    text-decoration: none;
    color: #222;
}

.list_trainers_pokemons_page .hidden {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: inline;
}

@media screen and (max-width: 900px) {
    .list_trainers_pokemons_page .gallery li {
        width: 33.33333%;
    }
}

@media screen and (max-width: 768px) {
    .list_trainers_pokemons_page .gallery li {
        width: 50%;
    }
}

@media screen and (max-width: 400px) {
    .list_trainers_pokemons_page .gallery li {
        width: 100%;
    }
}