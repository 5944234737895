.payment_header {
    align-items: center;
    background: #222;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 100%;
}

.payment_header_content {
    display: flex;
    gap: 5px;
    margin: 0 auto;
    max-width: 420px;
    width: 100%;
}

.payment_header_content_logo {
    height: 30px;
    width: 30px;
}

.payment_header_content_title {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
}

.payment_header_content_title_h2 {
    color: #fff;
}

.payment_header_content_title_text {
    color: #fff;
}

@media screen and (max-width: 480px) {
    .payment_header {
        display: none;
    }
}