.style_1_popup_white_base_button_orange {
    margin-top: 0px !important;
    padding: 21px 30px 19px !important;
    background: #ED6F2D !important;
    border-radius: 6px !important;
    line-height: 16.94px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    box-shadow: none !important;
    letter-spacing: 0px !important;
    font-family: Inter !important;
    height: 56px;
    color: white !important;
}

.style_1_popup_white_base_button_orange:hover {
    transition: 0.3s !important;
}

.style_1_popup_white_base_button_orange:hover,
.style_1_popup_white_base_button_orange:active{
    background-color: white !important;
    border: 1px solid #ed6f2d !important;
    color: #ed6f2d !important;
}

@media screen and (max-width: 992px){
    .style_1_popup_white_base_button_orange {
        border-radius: 12px !important;
    }
}