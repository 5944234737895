.trainers_list {
    grid-column-gap: 16px;
    grid-row-gap: 50px;
    -webkit-column-gap: 16px;
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(5, 19.35%);
    margin: 0 0 80px;
    padding-left: 0;
    row-gap: 50px;
}

@media screen and (max-width: 1919px) {
    .trainers_list {
        grid-template-columns: repeat(4, 24.3%);
    }
}

@media screen and (max-width: 1599px) {
    .trainers_list {
        grid-template-columns: repeat(3, 32.5%);
    }
}

@media screen and (max-width: 1280px) {
    .trainers_list {
        grid-template-columns: repeat(3, 32.4%);
    }
}

@media screen and (max-width: 1179px) {
    .trainers_list {
        gap: 50px 2%;
        grid-template-columns: repeat(2, 49%);
        margin-bottom: 90px;
        margin-top: 60px;
    }
}

@media screen and (max-width: 880px) {
    .trainers_list {
        width: 100%;
        padding: 0 12px;
        margin-top: 0px;
        gap: 12px 2%;
    }
}