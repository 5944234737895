.register_title {
    margin-bottom: 16px;
}

.confirmation_body {
    color: grey;
    line-height: 130%;
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

p.confirmation_body_text {
    line-height: 18.2px;
}

.link_all_orange {
    color: #ed6f2d !important;
    display: block;
    width: 100% !important;
    text-align: left !important;
}

.style_1_popup_white_title2.style_1_popup_white_title_no_link {
    display: flex;
    align-items: center;
    gap: 8px;
}

.style_1_popup_white_title2.style_1_popup_white_title_no_link img {
    position: relative;
    top: -1px;
}

@media screen and (max-width: 460px){
    .confirmation_pass_step_1 a.auth_registr_add {
        padding: 21px 30px 19px !important;
        background: #ED6F2D !important;
        border-radius: 12px !important;
        line-height: 16.94px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        text-transform: none !important;
        box-shadow: none !important;
        letter-spacing: 0px !important;
        font-family: Inter !important;
        height: 56px;
        color: white !important;
        text-align: center !important;

        position: absolute;
        bottom: 20px;
        left: 15px;
        right: 15px;
        margin: auto;
        width: auto !important;
    }

    .style_1_popup_white_title2.style_1_popup_white_title_no_link {
        box-shadow: none;
        border: none;
        margin-bottom: -100px !important;
        padding-bottom: 0px;
    }

    .style_1_popup_white_title2.style_1_popup_white_title_no_link + .confirmation_body {
        margin-top: -30px;
    }
}