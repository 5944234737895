.payment_form_card {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 420px;
    width: 100%;
}

.payment_form_card_form {
    background-color: #222;
    border-radius: 10px;
    padding: 25px 20px 40px;
    width: 100%;
}

.payment_form_card_form_title {
    color: #f8f8f8;
    margin: 0 0 25px;
}

.payment_form_card_form_input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
}

label.payment_form_card_form_input_label {
    color: #fff;
    left: 16px;
    position: absolute;
    top: 12px;
}

.style_1_base_input {
    align-items: flex-start;
    background: #454545;
    border: 1px solid #454545;
    border-radius: 6px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    outline: none;
    padding: 30px 16px 10px;
    text-transform: uppercase;
    transition: border .3s ease-in;
    width: 100%;
}

.style_1_base_input:hover {
    border: 1px solid #FFFFFF;
}

.style_1_base_input:focus {
    background-color: #222222;
    border: 1px solid #FFFFFF;
}

.style_1_base_input::placeholder {
    opacity: 1;
    transition: 0.3s;
}

.style_1_base_input:focus::placeholder {
    opacity: 0;
}

.payment_form_card_form_inputs {
    display: flex;
    gap: 15px;
}

.style_1_base_button_payment {
    background: #fff;
    border: none;
    border-radius: 6px;
    color: #222;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    outline: none;
    padding: 20px;
    width: 100%;
    cursor: pointer;
}

.style_1_base_button_payment.disable {
    cursor: default;
    opacity: .5;
}

.style_1_base_input_error {
    align-self: flex-start;
    color: #e75149;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    margin-top: 7px;
    text-align: center;
}

img.payment_form_card_form_input_img {
    display: block;
    height: 30px;
    position: absolute;
    right: 16px;
    top: 15px;
    width: 50px;
}

@media screen and (max-width: 480px) {
    .payment_form_card {
        display: contents;
    }

    .style_1_base_button_payment_body {
        position: absolute;
        padding-right: 12px;
        padding-left: 12px;
        left: 0;
        right: 0;
    }

    .style_1_base_button_payment_body {
        bottom: 20px;
    }

    .payment_form_card_form {
        padding-bottom: 28px;
    }

    .payment_form_card_form_input_last {
        margin-bottom: 0px;
    }
}