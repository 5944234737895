.single_page_pokemons_list {
    align-items: center;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background: #fff;
    border-radius: 20px;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    display: flex;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 0;
}

.single_page_pokemons_list_link {
    cursor: pointer;
    display: contents;
}

.single_page_pokemons_list_link_img {
    display: flex;
    height: 97px;
    width: 108px;
    padding-left: 20px;
}


.single_page_pokemons_list_link_content {
    padding: 20px 20px 20px 0;
}

.single_page_pokemons_list_link_content {
    display: flex;
    flex: auto;
    flex-direction: column;
    row-gap: 10px;
}

.single_page_pokemons_list_link_content_title {
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    line-height: normal;
    overflow-y: visible;
}

.single_page_pokemons_list_link_content_icons {
    -webkit-column-gap: 11px;
    column-gap: 11px;
    display: flex;
    margin-bottom: 10px;
}

.single_page_pokemons_list_link_content_icon img {
    height: 26px;
    width: 26px;
}

.single_page_pokemons_list_link_content_icon {
    font-size: 0;
}

.single_page_pokemons_list.feature-empty {
    opacity: .5;
}

.pokemon_one_body_content_inner_pokemons.feature-empty {
    opacity: .5;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}

@media screen and (max-width: 992px) {
    .single_page_pokemons_list {
        justify-content: space-evenly;
    }

    .single_page_pokemons_list_link_img {
        width: 106px;
        height: auto;
        padding-bottom: 0px;
        padding-top: 0px;
        object-fit: contain;
        padding-left: 0px;
    }

    .single_page_pokemons_list_link_content {
        margin-left: 30px;
        margin-right: auto;
    }
}

@media screen and (max-width: 768px) {
    .single_page_pokemons_list:last-child {
        margin-bottom: 0;
    }

    .single_page_pokemons_list_link {
        display: flex;
        width: 100%;
    }

    .single_page_pokemons_list_link_img {
        margin-left: 20px;
        margin-right: auto;
    }

    .single_page_pokemons_list_link_content_icons .level_or_attack {
        font-size: 12px;
        width: 67px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .single_page_pokemons_list_link_content_icon img {
        height: 24px;
        width: 24px;
    }

    .single_page_pokemons_list_link_content {
        flex: none;
        margin-left: auto;
        margin-right: 0;
        width: 50%;
    }

    .single_page_pokemons_list .copy_number {
        gap: 4px;
    }
}

@media screen and (max-width: 576px) {
    .single_page_pokemons_list_link_content {
        flex: none;
        margin-right: auto;
        margin-left: 30px;
    }
}