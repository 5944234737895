/* Таблица стилей бургер меню для фильтра */

.filter_type_burger_menu {
    float: left;
    position: relative;
}

.filter_type_burger_menu_body {
    display: none;
}

.filter_type_burger_menu_button {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.filter_type_burger_menu_body.open {
    display: block;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    gap: 20px;
    min-height: auto;
    padding: 26px 30px 10px;
    position: absolute;
    right: 0;
    top: 60px;
    width: 337px;
    z-index: 2;
}

.filter_type_burger_menu_top_reset{
    display: none;
}

.filter_type_burger_menu_body_filter_pokemon_wrap .filter_type_burger_menu_body_filter_title {
    margin-bottom: 0px;
}

.filter_type_burger_menu_body_filter_radio_p {
    align-items: center;
    background: hsla(240, 4%, 48%, .12);
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    margin: 0 0 16px;
    padding: 0;
}

label.filter_type_burger_menu_body_filter_radio_label {
    cursor: pointer;
    padding: 2px;
    text-align: center;
    width: 100%;
}

input.filter_type_burger_menu_body_filter_radio_label_checkbox {
    display: none;
    opacity: 0;
    visibility: hidden;
}

span.filter_type_burger_menu_body_filter_radio_label_span {
    align-items: center;
    color: #222;
    display: flex;
    gap: 2px;
    height: 100%;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 5px;
    width: 100%;
    transition: 0.3s;
}

input.filter_type_burger_menu_body_filter_radio_label_checkbox:checked+span.filter_type_burger_menu_body_filter_radio_label_span {
    background: #ed6f2d;
    border-radius: 7px;
    color: #fff;
}

.filter_type_burger_menu_body_filter_pokemon_wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eeeeef;
    padding: 16px 0;
}

label.filter_type_burger_menu_body_filter_pokemon_wrap_p {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

input.filter_type_burger_menu_body_filter_pokemon_wrap_p_switch {
    display: none;
}

span.filter_type_burger_menu_body_filter_switch_p_fake {
    border-radius: 50%;
    height: 31px;
    width: 31px;
    background: #e9e9eb;
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.filter_type_burger_menu_body_filter_pokemon_wrap_p_switch:checked+.filter_type_burger_menu_body_filter_switch_p_fake:before {
    display: block;
}

.filter_type_burger_menu_body_filter_switch_p_fake:before {
    background: #fff;
    border: 8px solid #ed6f2d;
    box-sizing: border-box;
    content: "";
    display: block;
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.filter_type_burger_menu_body_filter_switch_p_fake:before {
    border-radius: 50%;
    height: 31px;
    width: 31px;
}

.pokemon__filter-counter {
    display: none;
    transition: 0.2s;

    background: #ed6f2d;
    border-radius: 50%;
    color: #fff;
    font-family: SF Pro Text, Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    height: 18px;
    line-height: 19px;
    position: absolute;
    right: -4px;
    text-align: center;
    top: -4px;
    width: 18px;
    z-index: 1;
}

.pokemon__filter-counter.active {
    display: block;
}

button.filter_type_burger_menu_top_reset.active {
    display: block;
}

.filter_type_burger_menu_top_reset {
    align-items: center;
    background: #ed6f2d;
    border: none;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    display: none;
    font-family: Inter,Arial,Helvetica,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: 24px;
    justify-content: center;
    line-height: 16px;
    width: 94px;
}

.filter_type_burger_menu_top {
    display: flex;
    justify-content: space-between;
}

.filter_type_burger_menu_top {
    margin-bottom: 37px;
}

.filter_type_burger_menu_body_filter_title {
    margin-bottom: 12px;
}

.filter_type_burger_menu_body_bg, .filter_type_burger_menu_body_icon_close{
    display: none;
}

@media screen and (max-width: 480px){
    .filter_type_burger_menu_body_icon_close {
        align-self: center;
        background: url(../../assets/icons/base_close_vector_icon.svg) no-repeat 50%;
        border: none;
        cursor: pointer;
        height: 26px;
        width: 26px;
        transform: rotate(270deg);
        transition: 0.3s;
        display: block;
        margin-left: auto;
        margin-bottom: 10px;
    }

    .filter_type_burger_menu_body.open {
        position: fixed;
        bottom: 67px;
        left: 0;
        right: 0;
        width: 100%;
        height: fit-content;
        top: auto;
        border-radius: 15px 15px 0 0;
        z-index: 10;
        box-shadow: none;
        padding-bottom: 18px;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
    }

    span.filter_type_burger_menu_body_filter_radio_label_span {
        line-height: 28px;
    }

    .filter_type_burger_menu_top {
        margin-bottom: 36px;
        margin-top: -5px;
    }

    .filter_type_burger_menu_body.open:before {
        box-shadow: 0px -4px 30px 0px #191E2C1A;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 83px;
        bottom: -75px;
        border-radius: 12px;
        pointer-events: none;
    }

    .filter_type_burger_menu_body.open+.filter_type_burger_menu_body_bg {
        background: rgba(21, 21, 23, .5);
        display: block;
        content: '';
        position: fixed;
        left: 0;
        top: 0px;
        right: 0;
        bottom: 75px;
        z-index: 9;
    }

}