.pokemon_one_body {
    display: flex;
    position: relative;
    z-index: 2;
}

.pokemon_one_body_avatar_gradient {
    background: radial-gradient(59.88% 59.88% at 50% 50%, #f05828 0, #f7c615 44.27%, #ffdd65 73.96%, #ffe382 80.73%, #fff 100%);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0;
}

.pokemon_one_body_avatar_gradient {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: blur(169px);
    filter: blur(169px);
    left: -7%;
    opacity: .8;
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.pokemon_one_body_avatar_img {
    max-height: 474px;
    max-width: 540px;
    position: relative;
    width: 100%;
    z-index: 10;
    margin-right: 50px;
    height: 100%;
    min-height: 500px;
    object-fit: contain;
}

.pokemon_one_body_avatar {
    margin: 0 auto;
    max-height: 500px;
    text-align: center;
    width: 60%;
    z-index: 5;
}

.pokemon_one_body_content {
    z-index: 20;
}

p.pokemon_one_body_content_title_text {
    color: #222;
    opacity: 0.5;
}

h2.pokemon_one_body_content_title_icons_name {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: hidden !important;
}

.pokemon_one_body_content_title_icons {
    align-items: flex-end;
    display: flex;
    gap: 10px;
}

img.pokemon_one_body_content_title_icon_img {
    height: 40px;
    width: 40px;
}

.pokemon_one_body_content_inner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pokemon_one_body_content_inner_top {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pokemon_one_body_content_inner_hystory_battle {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pokemon_one_body_content_inner_box {
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 30px 22px;
}

.pokemon_one_body_content_inner_top_list_attr_one {
    align-items: center;
    display: flex;
    gap: 8px;
}

span.pokemon_one_body_content_inner_top_list_attr_one_name {
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 100%;
    min-width: 100px;
    opacity: .5;
    font-family: Inter, Arial, Helvetica, sans-serif;
    text-align: left;
}

.pokemon_one_body_content_inner_top_list_attr_one_text {
    width: 65px;
    font-size: 16px;
    font-weight: 400;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

ul.pokemon_one_body_content_inner_top_list_attr_one_slide {
    align-items: center;
    display: flex;
    gap: 3px;
    justify-content: flex-start;
    margin: 0;
    min-width: 160px;
    padding-left: 0;
    margin-right: auto;
    width: 100%;
}

li.pokemon_one_body_content_inner_top_list_attr_one_slide_i {
    background: #222;
    border-radius: 100px;
    height: 6px;
    list-style-type: none;
    margin: 0;
    opacity: .1;
    width: 21px;
}

.pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-active="1"]:first-child, .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-active="2"]:nth-child(-n+2), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-active="3"]:nth-child(-n+3), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-active="4"]:nth-child(-n+4), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-active="5"]:nth-child(-n+5), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-active="6"]:nth-child(-n+6), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-active="7"]:nth-child(-n+7) {
    background: #ed6f2d;
    opacity: 1;
}

.pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-full="1"]:not(:first-child), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-full="2"]:not(:nth-child(-n+2)), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-full="3"]:not(:nth-child(-n+3)), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-full="4"]:not(:nth-child(-n+4)), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-full="5"]:not(:nth-child(-n+5)), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-full="6"]:not(:nth-child(-n+6)), .pokemon_one_body_content_inner_top_list_attr_one_slide_i[data-full="7"]:not(:nth-child(-n+7)) {
    display: none;
    opacity: 0;
}

.pokemon_one_body_content_title {
    margin-bottom: 36px;
    max-width: 490px;
}

.pokemon_one_body_content_inner_trainer_info_avatar {
    align-items: flex-start;
    background-color: #dfdfdf;
    border-radius: 16px;
    display: flex;
    height: 68px;
    justify-content: center;
    overflow: hidden;
    width: 68px;
}

img.pokemon_one_body_content_inner_trainer_info_avatar_img {
    object-fit: contain;
    position: relative;
    top: 0;
    width: 150%;
}

.pokemon_one_body_content_inner_trainer_info {
    border: 1px solid #fff;
    border-radius: 20px;
    gap: 16px;
    padding: 20px;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    transition: 0.3s;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_top {
    gap: 6px;
    margin-bottom: 14px;
    align-items: center;
    display: flex;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_bottom {
    align-items: center;
    display: flex;
}

h4.pokemon_one_body_content_inner_trainer_info_avatar_right_top_title {
    overflow-y: visible !important;
    opacity: .5;
    margin: 0px;
}

span.pokemon_one_body_content_inner_trainer_info_avatar_right_top_subtitle {
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 18px;
    margin-bottom: -2px;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_bottom_icon_right {
    margin-left: auto;
    background-color: initial;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    rotate: 180deg;
}

.level {
    display: flex;
}

.level p {
    margin: 0px;
}

.pokemon_one {
    flex: 1 1;
}

.k_content_trainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

a.pokemon_one_body_content_inner_hystory_battle_link, .k_trainer_in_button {
    padding: 20px 19px 20px;
    box-shadow: none;
    height: 66px;
}

.k_cart_trainer_rate_img {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
}

.k_cart_trainer_rate_img_1{
    background-image: url(../../assets/icons/rating/Top–1.svg);
}

.k_cart_trainer_rate_img_2{
    background-image: url(../../assets/icons/rating/Top–2.svg);
}

.k_cart_trainer_rate_img_3{
    background-image: url(../../assets/icons/rating/Top–3.svg);
}

.k_cart_trainer_rate_img_10{
    background-image: url(../../assets/icons/rating/Top–10.svg);
}

.k_cart_trainer_rate_img_30 {
    background-image: url(../../assets/icons/rating/Top–30.svg);
}

.k_cart_trainer_rate_img {
    left: 2px;
    position: relative;
}

.attr_rate_trainer .single_page_body_content_inner_top_list_attr_one_text {
    align-items: center;
    display: flex;
    gap: 8px;
}

.k_content_trainer {
    margin-bottom: 40px;
}

.attr_id_trainer .copy_number {
    gap: 2px;
}

.k_exit_mobile_button{
    display: none;
}

@media screen and (max-width: 1599px) {
    .pokemon_one_body_content {
        max-width: 403px;
        min-width: 403px;
    }
}

@media screen and (max-width: 700px) {
    .pokemon_one_body_avatar_gradient {
        left: 0;
    }
}

@media screen and (max-width: 1600px) {
    .pokemon_one_body_avatar_gradient {
        max-width: 1127px;
    }
}

@media screen and (max-width: 1280px) {
    .pokemon_one_body_avatar_gradient {
        max-width: 867px;
    }
}

@media screen and (max-width: 992px) {
    .k_exit_mobile_button {
        display: block;
        padding: 20px 30px;
        background: #ED6F2D;
        color: white;
        border-radius: 12px;
        height: 56px;
        line-height: 17px;
        margin-top: 16px;
    }
}

@media screen and (max-width: 768px) {
    .pokemon_one_body_avatar_gradient {
        max-width: 1077px;
        min-width: 1077px;
    }

    .pokemon_one_body_content_inner_hystory_battle {
        max-width: 403px;
        min-width: 403px;
    }
}

@media screen and (min-width: 1600px){
    .k_content_trainer {
        min-width: 403px;
    }

    .single_page_body_content_inner_top {
        max-width: 403px;
    }
}