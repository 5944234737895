a.pokemon_one_body_content_inner_hystory_battle_link {
    align-items: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    color: #222;
    column-gap: 8px;
    cursor: pointer;
    display: flex;
    padding: 20px;
    text-decoration: none;
    z-index: 10;
    flex-direction: row;
}

.pokemon_one_body_content_inner_hystory_battle_link_button {
    margin-left: auto;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    background-color: initial;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    rotate: 180deg;
}

span.total-count.history-info_count {
    color: #222;
    opacity: .5;
}

.history-info {
    white-space: nowrap;
}

.history_battles.down {
    padding-bottom: 30px;
    margin-top: -15px;
    scrollbar-color: rgba(237, 111, 45, .6) #fff;
    scrollbar-width: 4px;
    display: flex;
    flex-direction: column;
    height: 485px;
    list-style: none;
    overflow-y: scroll;
    padding: 30px 13px 0 0;
    row-gap: 20px;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: calc(100% + 30px);
    transform-origin: top;
    transition: all .3s ease;
}

.history_battles_one {
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 403px;
}

.history_battles_one time.date {
    background: #fff;
    border: 1px solid rgba(34, 34, 34, .1);
    border-radius: 100px;
    color: rgba(0, 0, 0, .5);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    left: 50%;
    line-height: 15px;
    padding: 5px 10px;
    position: absolute;
    top: -12px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.history_battle_content {
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 25px 20px 16px;
    width: 100%;
}

.history_battle_content_cart_my {
    display: flex;
    gap: 10px;
}

.history_battle_content_link {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

img.history_battle_content_link_image {
    height: 54px;
    margin-bottom: 8px;
    width: 60px;
}

.history_battle_content_link_id {
    color: grey;
}

.history_battle_content_info {
    display: flex;
    flex-direction: column;
}

.history_battle_content_info_title {
    margin-bottom: 10px;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 73px;
    height: 18px;
}

.history_battles.down .k_load_more button {
    width: 100%;
}

.history_battles.down .k_load_more {
    margin-bottom: 12px;
}

.history_battle_content_status {
    align-items: center;
    border-radius: 4px 4px 20px 20px;
    -webkit-column-gap: 8px;
    column-gap: 8px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 100%;
    padding: 10px;
    background: #5ebc00;
    border-radius: 4px 4px 20px 20px;
    color: #fff;
}

.history_battle_content_status.defeat {
    background: #ef772a;
    color: #fff;
}

.history_battle_content_cart_other {
    display: flex;
    gap: 10px;
}

.history_battles {
    display: none;
}

a.pokemon_one_body_content_inner_hystory_battle_link {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .08);
}

.pokemon_one_body_content_inner_hystory_battle.feature-empty {
    opacity: .5;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}

.pokemon_one_body_content_inner_hystory_battle_link_button.down {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}



.history_battle_content_cart_my .history_battle_content_icon_batlle, .history_battle_content_cart_other .history_battle_content_icon_batlle {
    align-self: center;
}

.history_battle_content_icon_batlle {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 18px;
    width: 18px;
}

.history_battle_content_cart_my.win .history_battle_content_icon_batlle, .history_battle_content_cart_other.win .history_battle_content_icon_batlle {
    background-image: url("../../../assets/icons/sword.png");
}

.history_battle_content_cart_my.defeat .history_battle_content_icon_batlle, .history_battle_content_cart_other.defeat .history_battle_content_icon_batlle {
    background-image: url("../../../assets/icons/shield.png");
}

.history_battle_content_cart_my.dead img, .history_battle_content_cart_other.dead img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.history_battle_content_cart_my.dead .history_battle_content_info_title, .history_battle_content_cart_other.dead .history_battle_content_info_title {
    opacity: .5;
}

.history_battle_content_info_title {
    text-align: left;
}

.history_battle_content_cart_my.dead .level_or_attack, .history_battle_content_cart_other.dead .level_or_attack {
    border: 1px solid #222;
    opacity: .5;
    color: #222;
}

.history_battle_content_mobile {
    display: flex;
    gap: 10px;
}

.date_mobile, .history_battle_content_link_id_mobile{
    display: none;
}

.history_battle_content_cart_my.win .history_battle_content_icon_batlle {
    left: 2px;
}

.history_battle_content_cart_other.win .history_battle_content_icon_batlle {
    transform: scaleX(-1);
    left: -2px;
}

@media screen and (min-width: 1600px){
    .history_battles_one {
        max-width: 457px;
    }
}

@media screen and (max-width: 768px){
    .date_pc{
        display: none;
    }

    .date_mobile{
        display: contents;
    }

    .history_battle_content_link_id{
        display: none;
    }

    .history_battle_content_mobile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .history_battle_content_link_id_mobile {
        display: block;
    }

    .history_battle_content_info {
        gap: 10px;
    }

    .history_battle_content_info_title {
        margin: 0px;
        text-align: center;
        font-size: 14px;
    }

    img.history_battle_content_link_image {
        margin: 0px;
    }

    .history_battle_content_info .level_or_attack {
        width: 67px;
        height: 24px;
        font-size: 12px;
        padding: 5px 0px !important;
    }

    .history_battle_content_icon_batlle {
        min-width: 36px;
        height: 36px;
        top: 22px;
        position: relative;
    }

    .history_battle_content {
        justify-content: space-between;
    }

    .history_battle_content_cart_my, .history_battle_content_cart_other {
        justify-content: space-between;
        width: 100%;
    }

    .history_battle_content_info {
        align-items: center;
    }

    .history_battles_one {
        border-radius: 12px;
    }

    .history_battle_content_status {
        border-radius: 4px 4px 12px 12px;
    }

    .history_battle_content {
        padding: 18px 17px 20px;
        gap: 10px;
    }

    .history_battles.down {
        row-gap: 12px;
    }

    .history_battles_one time.date {
        top: 45px;
    }
}