.single_page {
    flex: 1 1;
}

.single_page_body{
    display: flex;
    position: relative;
    z-index: 2;
}

.single_page_body_avatar{
    margin: 0 auto;
    max-height: 500px;
    text-align: center;
    width: 60%;
    z-index: 5;
}

.attr_city_trainer .style_1_text_left {
    overflow: visible;
}

.single_page_body_avatar_gradient {
    background: radial-gradient(59.88% 59.88% at 50% 50%, #f05828 0, #f7c615 44.27%, #ffdd65 73.96%, #ffe382 80.73%, #fff 100%);
    bottom: 0;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: blur(169px);
    filter: blur(169px);
    left: -7%;
    opacity: .8;
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.single_page_body_avatar_img{
    max-height: 474px;
    max-width: 540px;
    position: relative;
    width: 100%;
    z-index: 10;
    margin-right: 50px;
    height: 100%;
    min-height: 500px;
    object-fit: contain;
}

.single_page_body_content {
    z-index: 20;
}

.single_page_body_content_title {
    margin-bottom: 31px;
    max-width: 490px;
}

.single_page_body_content_title_text {
    color: #222;
    opacity: 0.5;
}

.single_page_body_content_title_icons {
    align-items: flex-end;
    display: flex;
    gap: 10px;
}

.single_page_body_content_title_icons_name {
    color: #222;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.single_page_body_content_title_icon_img {
    height: 40px;
    width: 40px;
}

.single_page_body_content_title_icon{
    position: relative;
}

.single_page_body_content_inner {
    display: flex;
    gap: 20px;
}

.single_page_body_content_inner_top {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.single_page_body_content_inner_box {
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.single_page_body_content_inner_top_list_attr_one {
    align-items: center;
    display: flex;
    gap: 8px;
    max-height: 16px;
    margin-bottom: 20px;
}

.single_page_body_content_inner_top_list_attr_one.last_child {
    margin-bottom: 0px;
}

.single_page_body_content_inner_top_list_attr_one_name {
    color: #222;
    min-width: 100px;
    opacity: .5;
}

.single_page_body_content_inner_top_list_attr_one_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
}

.single_page_body_content_inner_top_list_attr_one {
    align-items: center;
    display: flex;
    gap: 8px;
    max-height: 16px;
}

.single_page_body_content_inner_top_list_attr_one_slide {
    align-items: center;
    display: flex;
    gap: 3px;
    justify-content: flex-start;
    margin: 0;
    min-width: 160px;
    padding-left: 0;
    margin-right: auto;
    width: 100%;
}

li.single_page_body_content_inner_top_list_attr_one_slide_i {
    background: #222;
    border-radius: 100px;
    height: 6px;
    list-style-type: none;
    margin: 0;
    opacity: .1;
    width: 21px;
}

.single_page_body_content_inner_top_list_attr_one_slide_i[data-active="1"]:first-child, .single_page_body_content_inner_top_list_attr_one_slide_i[data-active="2"]:nth-child(-n+2), .single_page_body_content_inner_top_list_attr_one_slide_i[data-active="3"]:nth-child(-n+3), .single_page_body_content_inner_top_list_attr_one_slide_i[data-active="4"]:nth-child(-n+4), .single_page_body_content_inner_top_list_attr_one_slide_i[data-active="5"]:nth-child(-n+5), .single_page_body_content_inner_top_list_attr_one_slide_i[data-active="6"]:nth-child(-n+6), .single_page_body_content_inner_top_list_attr_one_slide_i[data-active="7"]:nth-child(-n+7) {
    background: #ed6f2d;
    opacity: 1;
}

.single_page_body_content_inner_top_list_attr_one_slide_i[data-full="1"]:not(:first-child), .single_page_body_content_inner_top_list_attr_one_slide_i[data-full="2"]:not(:nth-child(-n+2)), .single_page_body_content_inner_top_list_attr_one_slide_i[data-full="3"]:not(:nth-child(-n+3)), .single_page_body_content_inner_top_list_attr_one_slide_i[data-full="4"]:not(:nth-child(-n+4)), .single_page_body_content_inner_top_list_attr_one_slide_i[data-full="5"]:not(:nth-child(-n+5)), .single_page_body_content_inner_top_list_attr_one_slide_i[data-full="6"]:not(:nth-child(-n+6)), .single_page_body_content_inner_top_list_attr_one_slide_i[data-full="7"]:not(:nth-child(-n+7)) {
    display: none;
    opacity: 0;
}

.attr_city_trainer .single_page_body_content_inner_top_list_attr_one_text {
    width: 150px;
}

.single_page_body_avatar {
    margin-top: 50px;
}

.single_page_body_content {
    margin-right: 1px;
}

.single_page_body_content_title {
    top: 1px;
    position: relative;
}

.pokemon_one_body_content_inner_hystory_battle_link_button {
    top: -2px;
    position: relative;
}

.total-count.history-info_count {
    margin-left: 4px;
}

.pokemon_one_body_content_inner_hystory_battle_link_button {
    right: -1px;
}

.history_battles.down {
    width: calc(100% + 13px);
}

.down .pokemon_one_body_content_inner_hystory_battle_link, .down .pokemon_one_body_content_inner_hystory_battle_link {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .08);
}

.pokemon_one_body_content_inner_hystory_battle.down {
    max-width: 403px;
}

.pokemon_one_body_content_inner_info_mobile{
    display: none;
    align-items: center;
    background: #fff;
    border-radius: 0 0 12px 12px;
    padding: 18px 12px;
    position: relative;
}

.pokemon_one_body_content_inner_info_mobile_button {
    background: #fff;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    height: 34px;
    left: 12px;
    margin-right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 34px;
}

.pokemon_one_body_content_inner_info_mobile_title {
    margin: 0;
    text-align: center;
    width: 100%;
    color: #222;
}

.single_page_pokemons_list.feature-empty {
    pointer-events: all;
    user-select: all;
}

button.copy_number_button {
    min-width: 20px;
}

.pokemon_one_body_content_inner_top_list_attr_one_text {
    text-align: left;
}

.pokemon_one_body_content_inner_top_list_attr_one_text .copy_number,
.pokemon_one_body_content_inner_trainer_info_avatar_right_bottom .copy_number{
    gap: 2px;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_bottom .level_or_attack {
    width: 92px;
    height: 26px;
    padding: 5px 0px !important;
}

.pokemon_one_body_content_inner_top_list_attr.pokemon_one_body_content_inner_box {
    padding-bottom: 30px;
}

ul.pokemon_one_body_content_inner_top_list_attr_one_slide {
    max-width: 160px;
}

.pokemon_one_body_content_title_icon_gold.die img.pokemon_one_body_content_title_icon_img {
    filter: grayscale(1);
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_top .single_page_body_content_title_icon img,
.pokemon_one_body_content_inner_trainer_info_avatar_right_top .single_page_body_content_title_icon{
    width: 20px;
    height: 20px;
}

.trainers_list, .pokemon_list {
    min-height: 55vh;
}

a:focus-visible, input:focus-visible, button:focus-visible {
    border: 1px solid #ED6F2D;
}

.k_load_more button:focus-visible {
    color: #ed6f2d;
    background: white;
}

@media screen and (min-width: 768px){
    .pokemon_one_body_content_inner_top_list_attr_one {
        height: 16px;
    }
}

@media screen and (min-width: 1599px){
    .single_page_body_content {
        margin-right: -1px;
    }

    .single_page_body_content_inner {
        margin-right: 1px;
        gap: 19px;
    }

    .k_mobile.k_content_trainer {
        position: relative;
        margin-top: 2px;
    }
}

@media screen and (min-width: 1919px){
    .single_page_body_content {
        margin-right: 0px;
    }

    .single_page_body_content_inner {
        margin-right: 0px;
        gap: 20px;
    }

    .k_mobile.k_content_trainer {
        margin-top: 1px;
    }
}

@media screen and (max-width: 1600px) {
    .single_page_body_avatar_gradient {
        max-width: 1127px;
    }
}

@media screen and (max-width: 1599px) {
    .single_page_body_content {
        max-width: 403px;
        min-width: 403px;
    }

    .single_page_body_content_inner {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

@media screen and (max-width: 1280px) {
    .single_page_body_avatar_gradient {
        max-width: 867px;
    }

    .single_page_body_content_title {
        max-width: 390px;
    }
}

@media screen and (max-width: 992px) {
    span.pokemon_one_body_content_inner_trainer_info_avatar_right_top_subtitle {
        max-width: 19vw;
    }

    span.pokemon_one_body_content_inner_top_list_attr_one_name, .pokemon_one_body_content_inner_top_list_attr_one_text {
        font-size: 14px;
    }

    .pokemon_one_body_content_inner_top_list_attr.pokemon_one_body_content_inner_box {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    p.pokemon_one_body_content_title_text {
        margin-bottom: 4px;
    }

    .pokemon_one_body_content_inner_trainer_info_avatar_right_bottom .level_or_attack {
        width: 82px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .trainers_list_card_right_top .single_page_body_content_title_icon {
        height: 18px;
        width: 18px;
    }

    .single_page_body {
        margin: 0;
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 80px;
    }

    .single_page_body_avatar_img {
        max-width: 258px;
        min-height: auto;
    }

    .single_page_body_content_inner_box {
        border-radius: 12px;
        grid-column: initial;
        grid-row: initial;
    }

    .single_page_body_content_inner_top_list_attr_one_name {
        width: 90px;
    }

    .single_page_body_content_inner_top_list_attr_one_text {
        width: 58px;
    }

    .single_page_body {
        margin-top: 15px;
    }

    .single_page_body_avatar {
        margin-top: 3px;
        margin-bottom: 17px;
    }

    .single_page_body {
        gap: 17px;
    }

    span.single_page_body_content_inner_top_list_attr_one_text.style_1_caption_16_400.style_1_text_left {
        overflow: visible;
    }

    .single_page_body_content_inner_top_list_attr_one {
        gap: 10px;
    }

    ul.single_page_body_content_inner_top_list_attr_one_slide {
        width: 116px;
        min-width: 116px;
    }

    .single_page_body_content_inner_box {
        padding: 22px 20px;
    }

    a.pokemon_one_body_content_inner_hystory_battle_link, .pokemon_one_body_content_inner_trainer_info, .pokemon_one_body_content_inner_box {
        border-radius: 12px;
    }

    .k_content_trainer {
        gap: 8px;
    }

    .pokemon_one_body_content_inner_hystory_battle_link_button {
        right: 0px;
    }

    span.k_cart_trainer_export_button_border {
        border-radius: 6px;
    }

    .k_trainer_in_button_title {
        font-size: 16px !important;
        line-height: 16px;
        top: 2px;
        position: relative;
    }

    .k_trainer_in_button {
        border-radius: 20px;
    }
}

@media screen and (max-width: 768px) {

    .trainers_list_card_right_top .single_page_body_content_title_icon img {
        max-width: 18px;
        max-height: 18px;
    }

    .trainers_list_card_right_top .single_page_body_content_title_icon img {
        top: -4px;
    }

    .trainers_list_card_left_img {
        max-height: 35vw;
        min-height: 35vw;
    }

    .pokemons_list_card_center_name,
    .trainers_list_card_right_top_name{
        font-size: 16px;
    }

    .trainers_list_card_left {
        min-height: auto;
        max-height: 40vw;
    }

    .trainers_list_card_left img {
        width: 130%;
        left: -15%;
    }

    .history_battles.down {
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
        height: 100%;

        padding-bottom: 100px;
        align-items: center;
    }

    .single_page_body_avatar_gradient {
        max-width: 1077px;
        min-width: 1077px;
    }

    .single_page_body_content {
        margin: 0 auto;
        width: 403px;
        max-width: 100%;
        min-width: auto;
    }

    .single_page_body_content_title_icons_name {
        font-size: 32px;
    }

    .single_page_body_content_title_icon:after {
        background-color: #fff;
        border-radius: 100%;
        box-sizing: border-box;
        content: "";
        display: block;
        height: 40px;
        left: 50%;
        padding: 8px;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 40px;
        z-index: -1;
    }

    .single_page_body {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .single_page_body_avatar_img {
        max-width: 258px;
    }

    .down .pokemon_one_body_content_inner_info_mobile{
        display: flex;
    }

    .pokemon_one_body_content_inner_hystory_battle.down, .pokemon_one_body_content_inner_pokemons.down {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99999999;
        background: white;
        max-width: 100%;
    }

    .pokemon_one_body_content_inner_hystory_battle.down:before, .pokemon_one_body_content_inner_pokemons.down:before {
        background: #fff radial-gradient(farthest-corner at bottom, rgba(240, 88, 40, .4) 13%, rgba(247, 198, 21, .4) 59.27%, rgba(255, 221, 101, .4) 76.96%, rgba(255, 227, 130, .4) 85.73%, hsla(0, 0%, 100%, .4) 110%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .pokemon_one_body_content_inner_hystory_battle.down .pokemon_one_body_content_inner_hystory_battle_link , .pokemon_one_body_content_inner_pokemons.down .pokemon_one_body_content_inner_hystory_battle_link {
        display: none;
    }

    .single_page_body_content_title_icon_img {
        width: 24px;
        height: 24px;
    }

    .single_page_body_content_title_icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: auto;
        margin-top: auto;
    }
}

@media screen and (max-width: 700px) {
    .single_page_body_avatar_gradient {
        left: 0;
    }

    .single_page_body_avatar {
        max-width: 258px;
        width: 100%;
    }

    .pokemon_one_body_avatar_img {
        min-height: auto;
    }
}

@media screen and (max-width: 450px) {
    .single_page_body_content {
        width: 100%;
    }

    .single_page_body {
        gap: 0;
    }

    .single_page_body_avatar_img {
        max-width: 258px;
    }

    .single_page_body_content_inner, .single_page_body_content_inner_top {
        gap: 8px;
    }

    .single_page_body_content_inner_box {
        padding: 20px;
    }

    .single_page_body_content_inner_top_list_attr_one_name {
        font-size: 14px;
    }

    .single_page_body_content_inner_top_list_attr_one_text {
        margin-right: 20px;
        font-size: 14px;
    }

    .pokemon_one_body_content_inner_pokemons, .pokemon_one_body_content_inner_hystory_battle {
        min-width: auto;
        max-width: 100%;
    }

    .single_page_body_avatar_img {
        height: 231px;
    }

    .single_page {
        padding: 0 12px;
    }
}

@media screen and (max-width: 425px) {
    .history_battles.down {
        align-items: normal;
    }

    .single_page_body_content_title {
        margin-bottom: 24px;
        max-width: 100%;
    }

    .single_page_body_content_title_text {
        margin-bottom: 4px;
    }

    .single_page_body_content_title_icons {
        align-items: center;
        min-height: 40px;
    }

    .single_page_body_content_title_icons_name {
        font-size: 24px;
        max-width: 81%;
    }
}

@media screen and (max-width: 340px) {
    .pokemon_one_body_content_inner_top_list_attr_one {
        flex-direction: column;
    }

    .pokemon_one_body_content_inner_top_list_attr_one_name {
        width: 100%;
    }

    .pokemon_one_body_content_inner_top_list_attr_one_text {
        width: 100%;
        text-align: left;
    }

    .pokemon_one_body_content_inner_top_list_attr_one_slide {
        width: 100%;
        justify-content: flex-start;
    }

    .pokemon_one_body_content_inner_trainer_info_avatar {
        display: none;
    }
}