.k_field_passwords {
    display: flex;
    gap: 26px;
}

p.k_password_info {
    max-width: 170px;
    color: grey;
    margin-bottom: 0px;
    margin-top: 10px;
}

@media screen and (max-width: 460px) {
    .page_change_password_step_1 a.style_1_popup_white_link_standart {
        display: none;
    }

    .page_change_password_step_1 button.MuiButtonBase-root {
        position: absolute;
        bottom: 20px;
        left: 15px;
        right: 15px;
        margin: auto;
        width: auto;
    }
}