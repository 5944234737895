button.payment_status_back {
    background-color: inherit;
    border: none;
    cursor: pointer;
    display: flex;
    gap: 11px;
    margin: 0;
    padding: 0;
}

.payment_form_card_form.payment_success, .payment_form_card_form.payment_mistake {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.payment_status_top {
    align-items: center;
    display: flex;
    gap: 15px;
}

.payment_status_top_text {
    margin-top: -17px;
}

.payment_status_top_title {
    color: #f8f8f8;
    margin: 0 0 -1px;
}

img.success__image {
    height: 28px;
    width: 28px;
}

.payment_result_page .payment_main_title {
    display: none;
}

@media screen and (max-width: 768px){

.payment_result_page button.payment_status_back {
    position: absolute;
    bottom: 20px;
    margin: 0 12px;
    border-radius: 12px;
    background: white;
    left: 0;
    right: 0;
    width: -webkit-fill-available;
    justify-content: center;
    padding: 21.5px 0;
}

.payment_result_page button.payment_status_back p {
    font-size: 14px;
    color: black;
}

.payment_result_page button.payment_status_back img {
    display: none;
}

.payment_result_page .payment_main_title {
    display: flex;
}

}