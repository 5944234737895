/* Загрузка шрифтов */
@font-face {
  font-family: 'Act of Rejection';
  src: url('./assets/fonts/Act of Rejection Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter V Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Pokemon Classic';
  src: url('./assets/fonts/Pokemon Classic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.style_1_caption_8_700{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
}

.style_1_caption_12_400{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.style_1_caption_12_500 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06px;
}

.style_1_caption_12_700 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.06px;
}

.style_1_caption_13_500{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
}

.style_1_caption_14_400{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}

.style_1_caption_14_500{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}

.style_1_caption_16_500 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.1px;
}

.style_1_caption_16_400{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
}

.style_1_caption_18_500 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.style_1_caption_20_500{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
}

.style_1_caption_24_500{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
}

.style_1_classic_26_400{
  font-family: 'Pokemon Classic';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 100%;
}

.style_1_line_height_42px{
  line-height: 42.25px;
}

.style_1_line_height_14_52px{
  line-height: 14.52px;
}

.style_1_text_grey{
  color: grey;
}

.style_1_text_left{
  text-align: left;
}

.style_1_text_uppercase{
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
}

.style_1_heading_38_400_pokemon_classic {
  font-family: 'Pokemon Classic';
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 62px;
  margin: 0px;
}

@media screen and (max-width: 480px){
  .style_1_heading_38_400_pokemon_classic {
    font-size: 24px;
    line-height: 39px;
  }
}

/* Контент и отступы */

@media screen and (min-width: 1920px){
  body {
    padding: 40px 56px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1919px){
  body {
    padding: 40px 48px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1599px){
  body {
    padding: 40px 40px;
  }
}

@media screen and (max-width: 1279px){
  body {
    padding: 16px 12px;
  }
}

/* Остальное */

header.header {
  padding-top: 40px;
}

p {
  margin: 0px;
}

body{
  background: #F0F0F0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  outline: none;
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 150px auto 0;
  max-width: 350px;
  position: relative;
  text-align: center;
  z-index: 1;
}

img.empty__img {
  width: auto;
}

.empty__img {
  margin-bottom: 24px;
  margin-top: -10vh;
  max-height: 219px;
  max-width: 229px;
  object-fit: cover;
  width: 100%;
}

.empty__subtitle, .empty__title {
  color: #222;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
}

.empty__title {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 16px;
  margin-top: 0;
}

.empty__subtitle {
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}

.k_load_more {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 100px;
}

.pokemon_list + .k_load_more {
    margin-bottom: 180px;
}

.style_1_button_background {
  width: 178px;
  height: 48px;
  padding: 0px;
  border-radius: 12px;
  background: #ed6f2d;
  border: 1px solid #ed6f2d;
  color: #fff;
  cursor: pointer;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
}

body {
  background: #eee;
  font-family: Inter, Arial, Helvetica, sans-serif;
  padding-top: 0!important;
  padding-bottom: 0!important;
  margin: 0;
  width: 100%;
}

::-webkit-scrollbar-thumb {
  background: rgba(237,111,45,.5);
  border-radius: 100px;
  height: 230px;
  visibility: hidden;
}

.MuiInputBase-root.MuiOutlinedInput-root input {
  line-height: 17px;
  padding: 27px 16px 10px !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  top: 3px !important;
  color: grey !important;
}

.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined {
  position: absolute !important;
  right: 15px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0px !important;
}

fieldset.MuiOutlinedInput-notchedOutline legend {
  display: none !important;
}

fieldset.MuiOutlinedInput-notchedOutline legend {
  display: none !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}

.MuiOutlinedInput-root.Mui-error {
  border: 1px solid #E75149;
}

/* Градиент */

.gradient{
  background: radial-gradient(59.88% 59.88% at 50% 50%,#f05828 0,#f7c615 44.27%,#ffdd65 73.96%,#ffe382 80.73%,#fff 100%);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: -1;

  -webkit-filter: blur(100px);
  filter: blur(100px);
  opacity: .5;
}

.gradient_bottom {
  margin-top: -25%;
  top: 50%;
}

@media screen and (min-width: 1280px){
  .gradient_bottom {
    margin-top: 0%;
    top: 50%;
  }
}

/* Градиент END */

/* Попапы, окна и всё с этим связанное */

section.style_1_popup_white_out {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: center;
  margin: 80px 0 50px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}

.style_1_popup_white_in {
  background: white;
  border-radius: 15px;
  margin: 0 auto 20px;
  max-width: 420px;
  padding: 40px 30px;
  position: relative;
  width: 100%;
  z-index: 10;
}

@media screen and (max-width: 460px) {
  section.style_1_popup_white_out {
    margin-top: 0px;
  }

  .style_1_popup_all_page .style_1_popup_white_in {
    height: 100%;
    border-radius: 0;
    padding-top: 89px;
  }

  .style_1_popup_white_out {
    background: linear-gradient(42.24deg, #ed6f2d 13.34%, #ffd600 106.42%), #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;
    margin: 0;
    padding: 0;
    justify-content: space-between;
  }

  .style_1_popup_white_in {
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    margin: auto 0 0;
    max-width: 100%;
    padding: 25px 12px;
  }
}

@media screen and (max-height: 1024px){
  section.style_1_popup_white_out {
    position: relative;
  }

  .auth {
    width: 100% !important;
  }
}

@media screen and (max-height: 640px){
  .style_1_popup_white_in {
    max-height: 485px;
    height: 485px;
    overflow: scroll;
  }

  .auth_mobile_top {
    height: 150px;
  }
}

/* Попапы и всё с этим связанное END */


@media screen and (max-width: 480px) {
  .k_mobile_visible {
    display: block !important;
    z-index: 10;
  }

  body{
    padding: 0px !important;
  }
}

@media screen and (max-width: 450px){
  .style_1_caption_16_400 {
    font-size: 14px;
  }
}

/* Убрать отсюда в инпуты*/

.k_form_control {
  margin-bottom: 45px !important;
  position: relative !important;
  margin-top: 0px !important;
}

.style_1_popup_white_form_wrapper_fields {
  margin-bottom: 20px;
  margin-top: 25px;
}

.MuiInputBase-root.MuiOutlinedInput-root input {
  line-height: 17px;
  padding: 24px 16px 10px !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  top: 5px !important;
  left: 2px !important;
  color: grey !important;
}

.MuiInputBase-root.MuiOutlinedInput-root input[name="password"],
.MuiInputBase-root.MuiOutlinedInput-root input[name="password_2"],
.MuiInputBase-root.MuiOutlinedInput-root input[name="password_1"]{
  padding-right: 45px !important;
}

/* Убрать отсюда в инпуты END*/

.empty {
  max-width: 100vw;
  width: calc(100vw - 96px);
  margin: 123px 0px 120px;
}

.pokemon_list, .trainer_list {
  min-height: 50vh;
}

html::-webkit-scrollbar, .history_battles {
    display: none;
}

html, .history_battles {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media screen and (max-width: 1600px){
  .empty {
    width: calc(100vw - 80px);
  }
}

@media screen and (max-width: 1280px){
  .empty {
    width: calc(100vw - 24px);
  }
}

@media screen and (max-width: 992px){
  .App {
    justify-content: right;
  }
}

@media screen and (max-width: 880px){
  .empty {
    width: calc(100vw - 36px);
  }
}

@media screen and (max-width: 480px){
  .empty {
    width: calc(100vw - 24px);
  }
}