header nav {
    display: flex;
}

header .nav_main {
    display: flex;
    align-items: center;
    gap: 12px;
}

.header_card_trainer_id {
    color: black;
}

.right_block {
    margin-left: auto;
}

header.header {
    z-index: 9999;
    position: relative;
    padding: 40px 0;
}


/* Карточка тренера в шапке */

.header_card_trainer {
    position: relative;
}

.header_mobile_list_one_link_profile_premium_text {
    z-index: 9999;
    position: absolute;
    background: #ED6F2D;
    color: white;
    right: 0px;
    top: -8px;
    border-radius: 16px;
    padding: 0px 4px;
    line-height: 16px;
    width: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_card_trainer {
    min-width: 50px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    gap: 18px;
    padding-left: 17px;
    padding-right: 9px;
}

.header_card_trainer_id_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    opacity: 0.5;
}

.header_card_trainer_id {
    display: flex;
    gap: 3px;
}

.header_card_trainer_id_num {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
}

.header_card_trainer_img {
    position: relative;
    background: #e9e9eb;
    border-radius: 8px;
    height: 40px;
    overflow: hidden;
    width: 43px;
}

img.k_main_photo {
    margin-top: 6px;
    width: 101%;
}

@media screen and (max-width: 992px) {
    .header>nav{
        display: none;
    }

    header.header{
        padding: 0px;
    }
}