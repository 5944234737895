.pokemon_one_body {
    display: flex;
    position: relative;
    z-index: 2;
}

.pokemon_one_body_avatar_gradient {
    background: radial-gradient(59.88% 59.88% at 50% 50%, #f05828 0, #f7c615 44.27%, #ffdd65 73.96%, #ffe382 80.73%, #fff 100%);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0;
}

.pokemon_one_body_avatar_img.die {
    filter: grayscale(100%);
}

.pokemon_one_body_avatar_gradient {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: blur(169px);
    filter: blur(169px);
    left: -7%;
    opacity: .8;
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.pokemon_one_body_avatar_img {
    max-height: 474px;
    max-width: 540px;
    position: relative;
    width: 100%;
    z-index: 10;
    margin-right: 50px;
    height: 100%;
    min-height: 500px;
    object-fit: contain;
}

.pokemon_one_body_avatar {
    margin: 0 auto;
    max-height: 500px;
    text-align: center;
    width: 60%;
    z-index: 5;
}

.pokemon_one_body_content {
    z-index: 20;
}

p.pokemon_one_body_content_title_text {
    color: #222;
    opacity: 0.5;
}

h2.pokemon_one_body_content_title_icons_name {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: hidden !important;
}

.pokemon_one_body_content_title_icons {
    align-items: flex-end;
    display: flex;
    gap: 10px;
}

img.pokemon_one_body_content_title_icon_img {
    height: 40px;
    width: 40px;
}

.pokemon_one_body_content_inner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pokemon_one_body_content_inner_top {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pokemon_one_body_content_inner_hystory_battle, .pokemon_one_body_content_inner_pokemons {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pokemon_one_body_content_inner_box {
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 30px 22px;
}

.pokemon_one_body_content_inner_top_list_attr_one {
    align-items: center;
    display: flex;
    gap: 8px;
}

span.pokemon_one_body_content_inner_top_list_attr_one_name {
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 100%;
    min-width: 100px;
    opacity: .5;
    font-family: Inter, Arial, Helvetica, sans-serif;
    text-align: left;
}

.pokemon_one_body_content_inner_top_list_attr_one_text {
    width: 65px;
    font-size: 16px;
    font-weight: 400;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

ul.pokemon_one_body_content_inner_top_list_attr_one_slide {
    align-items: center;
    display: flex;
    gap: 3px;
    justify-content: flex-start;
    margin: 0;
    min-width: 160px;
    padding-left: 0;
    margin-right: auto;
    width: 100%;
}

.pokemon_one_body_content_title {
    margin-bottom: 36px;
    max-width: 490px;
}

.pokemon_one_body_content_inner_trainer_info_avatar {
    align-items: flex-start;
    background-color: #dfdfdf;
    border-radius: 16px;
    display: flex;
    height: 68px;
    justify-content: center;
    overflow: hidden;
    width: 68px;
}

.pokemon_one_body_content_inner_trainer_info:hover {
    border: 1px solid #ED6F2D;
    cursor: pointer;
}

img.pokemon_one_body_content_inner_trainer_info_avatar_img {
    object-fit: contain;
    position: relative;
    top: 0;
    width: 150%;
}

.pokemon_one_body_content_inner_trainer_info {
    border: 1px solid #fff;
    border-radius: 20px;
    gap: 16px;
    padding: 20px;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_top {
    gap: 6px;
    margin-bottom: 14px;
    align-items: center;
    display: flex;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_bottom {
    align-items: center;
    display: flex;
}

h4.pokemon_one_body_content_inner_trainer_info_avatar_right_top_title {
    overflow-y: visible !important;
    opacity: .5;
    margin: 0px;
}

span.pokemon_one_body_content_inner_trainer_info_avatar_right_top_subtitle {
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_bottom_icon_right {
    margin-left: auto;
    background-color: initial;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    rotate: 180deg;
}

.level {
    display: flex;
}

.level p {
    margin: 0px;
}

.pokemon_one {
    flex: 1 1;
}

.pokemon_one_body_content_inner_trainer_info_avatar_right_bottom .level_or_attack {
    margin-right: 14px;
}

@media screen and (max-width: 1599px) {
    .pokemon_one_body_content {
        max-width: 403px;
        min-width: 403px;
    }
}

@media screen and (max-width: 700px) {
    .pokemon_one_body_avatar_gradient {
        left: 0;
    }
}

@media screen and (max-width: 1600px) {
    .pokemon_one_body_avatar_gradient {
        max-width: 1127px;
    }
}

@media screen and (max-width: 1280px) {
    .pokemon_one_body_avatar_gradient {
        max-width: 867px;
    }
}

@media screen and (max-width: 768px) {
    .pokemon_one_body_content_inner_trainer_info_avatar_right_bottom .level_or_attack {
        font-size: 12px;
    }

    .pokemon_one_body_avatar_gradient {
        max-width: 1077px;
        min-width: 1077px;
    }

    .pokemon_one_body_content_inner_hystory_battle, .pokemon_one_body_content_inner_pokemons {
        max-width: 403px;
        min-width: 403px;
    }
}

