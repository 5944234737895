#k_is_all{
    display: none !important;
}
.not-found {
    height: calc(100vh - 140px - 135px);
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.k_error_screen{
    display: none;
}
.not-found__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 23px;
}
.not-found__title {
    display: none;
}
.not-found__status {
    max-width: 538px;
    width: 100%;
    margin-top: 35px;
    position: relative;
}
.not-found__status-bg {
    width: 100%;
    object-fit: cover;
}
.not-found__text {
    font-family: "Act of Rejection", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 79px;
    line-height: 79px;
    color: #222222;
    margin: 0;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
}

.not-found__pikachu {
    max-width: 608px;
    width: 100%;
    object-fit: cover;
    transform: rotate(-2deg);
}

@media screen and (max-width: 1260px) {
    .not-found__status,
    .not-found__pikachu {
        max-width: 450px;
    }
    .not-found__text {
        font-size: 56px;
        top: 40%;
        left: 0;
        transform: translate(0, -40%);
    }
}

@media screen and (max-width: 992px) {
    .not-found {
        height: calc(100vh - 76px);
    }
    .not-found__status,
    .not-found__pikachu {
        max-width: 300px;
    }

}

@media screen and (max-width: 768px) {
    .not-found {
        height: calc(100vh - 80px);
    }
    .not-found__container {
        flex-direction: column;
        gap: 0;
        align-items: center;
        height: 100%;
    }
    .not-found__status {
        margin-top: 0;
    }

    .not-found__title {
        display: block;
        font-family: "Pokemon Classic";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 39px;
        text-align: center;
        color: #222222;
        z-index: 10;
        margin-top: 40px;
        margin-bottom: auto;
    }
    .not-found__text {
        font-size: 40px;
    }
}
@media screen and (max-width: 450px) {
    .not-found {
        height: calc(100dvh - 80px);
    }
}