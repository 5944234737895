.payment_main_title {
    align-items: center;
    display: none;
    justify-content: center;
    margin: 47px auto 0;
    max-width: 420px;
    position: relative;
    width: 100%;
}

h2.payment_main_title_h2 {
    color: #fff;
}

.payment_main_title_link {
    border: none;
    border-radius: 100px;
    cursor: pointer;
    height: 34px;
    left: 0;
    position: absolute;
    top: -6px;
    width: 34px;
    background: #0f0f0f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment_main_title_link img {
    filter: invert(1);
}

@media screen and (max-width: 480px){
    .payment_main_title{
        display: flex;
    }
}