/* Старые стили */

.profile {
    position: relative;
    z-index: 9;
}

.profile__email-f {
    padding-bottom: 22px;
}

.profile__container {
    border-bottom: 1px solid #eeeeef;
}

.k_profile_title_left {
    max-width: 200px;
    word-break: break-all;
}

.profile__text.k_email_name.k_profile_title_left {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 18px;
}

.profile__title {
    margin-top: 0;
    margin-bottom: 43px;
    font-family: "Pokemon Classic";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    color: #222222;
}

.profile__label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-top: 0;
    margin-bottom: 10px;
}

.profile__subtitle {
    color: #808080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mb-16 {
    margin-top: 0;
    margin-bottom: 16px;
}

.p-22 {
    padding: 22px 0;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-center {
    text-align: center;
}

.profile__button {
    cursor: pointer;
    background: #ed6f2d;
    padding: 15px 29px;
    width: -moz-fit-content;
    width: fit-content;
    outline: none;
    border: 1px solid #ed6f2d;
    border-radius: 12px;
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    transition: all 0.3s ease-in-out;
    text-align: center;
}

.pok-single .achievements {
    display: none !important;
}

.profile__button:hover {
    background: #fff;
    color: #ed6f2d;
}

.k_google_connect .profile__button:hover{
    background-color: white !important;
    border: 1px solid #ed6f2d !important;
    color: #ed6f2d !important;
}

.k_google_connect .delete_method:hover {
    background: #ed6f2d !important;
    color: white !important;
}

.profile__button-right {
    margin-left: auto;
}

.profile__button-light {
    border: 1px solid #ed6f2d;
    background: transparent;
    color: #222222;
    min-width: 145px;
}

.profile__button-light:hover {
    background: #ed6f2d;
    color: #fff;
}

.profile__title-grey {
    color: #808080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 10px;
}

.profile__text {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #222;
}

.profile__text-grey {
    color: #808080;
    opacity: 0.6;
}

.profile__input.active {
    color: #222222;
    opacity: 0.6;
}

.profile__back-button {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 40px;
    background: #ffffff;
    border-radius: 15px;
    padding: 9px 18px 10px 9px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    border: 1px solid #ffffff;
    transition: border 0.3s ease-in;
    position: relative;
    z-index: 10;
    letter-spacing: -0.2px;
}

.profile__back-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    margin: 0;
}

.profile__back-button:hover {
    border: 1px solid #ed6f2d;
}

.profile-mobile {
    display: none;
}

.profile__title-mobile {
    color: #222222;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

form.auth__form.profile__email-f.profile__container.k_profile_email_register {
    position: relative;
    padding-bottom: 30px;
}

button.profile__button.profile__button-right.k_send_mail_confirm {
    float: right;
}

.k_pochta_ok {
    text-align: center;
    color: green;
    font-size: 18px;
    margin-top: -20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.k_pochta_error_m {
    text-align: center;
    color: red;
    font-size: 18px;
    margin-top: -20px;
}

button.profile__button.profile__button-right.k_send_mail_renew {
    display: flow-root;
}

.k_profile_email_confirm, .k_profile_email_register {
    padding-top: 22px;
}

h1.profile__title {
    margin-bottom: 21px;
}

.k_pochta_error_m {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #E75149;
}

.df.register__df {
    align-items: baseline;
}

@media screen and (min-width: 992px){

    section.auth.profile {
        margin-top: -46px;
        /*margin-bottom: 0px;*/
    }

    .profile input+label {
        left: 16px;
        top: 20px;
    }

    .profile .df.register__df .auth__wrap {
        width: 46.5%;
    }

    .profile .df.register__df {
        justify-content: space-between;
    }

    .profile__container.p-22.flex.k_profile_email_ok+div {
        padding-top: 16px;
        border: none !important;
        padding-bottom: 0px !important;
        margin-bottom: -5px;
    }

    .k_google_connect {
        left: 0px;
        position: relative;
    }

    .register__title_er {
        margin-bottom: 39px;
    }

    input#password+label+span+span.auth__error {
        margin-bottom: 16px;
    }

    .k_profile_email_confirm > h4 + p.profile__subtitle.mb-16 {
        margin-bottom: 10px;
    }

    button.profile__button.profile__button-right.k_send_mail_renew {
        margin-top: -9px;
        padding: 15px 32px;
    }
}

@media screen and (max-width: 992px) {
    @media screen and (max-height: 630px){
        .profile {
            height: fit-content !important;
            padding-bottom: 60px !important;
        }
    }

    .profile__text.k_email_name.k_profile_title_left {
        min-height: 20px;
    }

    form.auth__form {
        background: white;
    }

    form.auth__form.profile__email-f.profile__container.k_profile_email_register {
        padding-bottom: 21px;
    }

    .profile__container.p-22.flex.k_profile_email_ok+div {
        margin-top: -6px;
    }

    .k_google_connect {
        left: 0px;
        position: relative;
    }

    .profile__button {
        padding: 14px 29px;
    }

    .back-button {
        padding: 4px;
        border-radius: 100%;
    }
    .back-button__text {
        display: none;
    }

    .profile__back-button {
        margin-top: 40px;
    }
}
@media screen and (max-width: 768px) {


    button.profile__button.profile__button-right.k_send_mail_confirm {
        float: right;
    }
    .back-button {
        position: absolute;
        top: 0;
        left: 0;
    }
    .k_profile_email_register, .profile__container {
        height: fit-content !important;
    }
    .profile__button {
        min-width: 144px;
    }
    .k_profile_email_ok + div {
        border-bottom: 1px solid #eeeeef;
        /*border-top: 1px solid #eeeeef;*/
        margin-top: 22px;
    }
}


@media screen and (max-width: 460px) {
    button.profile__button.profile__button-right.k_send_mail_confirm {
        margin-top: 0px;
    }

    form.auth__form.profile__email-f.profile__container.k_profile_email_register,
    form.auth__form.profile__email-f.profile__container.k_profile_email_confirm{
        display: flex;
    }

    .login__content.profile .profile__container:last-child {
        border-bottom: navajowhite !important;
    }
    .login__content.profile .profile__container, .login__content.profile .auth__form  {
        padding-left: 12px;
        padding-right: 12px;
    }

    .profile {
        border-radius: 0px;
        height: 100vh;
    }

    .profile__button {
        font-size: 14px;
    }

    .profile__wrap {
        border-radius: 0;
        min-height: 100vh;
    }

    .profile__back-button,
    .profile__title {
        display: none;
    }

    .profile-mobile {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 24px;
    }


    .profile__back-button-mobile {
        border: 1px solid #ed6f2d;
        border-radius: 100%;
        width: 38px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile__title-mobile {
        text-align: center;
        width: 100%;
    }


}

/* Новые стили */


.profile_page {
    flex: 1 1;
    text-align: left;
}

section.auth.profile .k_cart_trainer_export {
    width: 420px;
    margin-bottom: 40px;
}

section.auth.profile {
    flex-direction: column;
}

.k_cart_trainer_export_box_desc .copy_number_id {
    font-size: 16px;
}

@media screen and (max-width: 992px) {
    .k_cart_trainer_export_mobile {
        display: flex !important;
    }
    .k_cart_trainer_export_mobile{
        display: flex !important;
    }

    section.auth.profile .k_cart_trainer_export {
        width: 100%;
    }

    section.auth.profile .k_cart_trainer_export {
        margin-bottom: 0px;
    }

    section.auth.profile .k_cart_trainer_export .copy_number_id {
        word-break: break-all;
    }

    .profile_page a.top_back_button {
        display: none;
    }

    .k_cart_trainer_export_box_desc .copy_number_id {
        font-size: 12px;
    }

    .k_cart_trainer_export.k_cart_trainer_export_mobile {
        padding: 0 10px;
    }

    .k_cart_trainer_export_mobile .copy_number {
        justify-content: space-between;
    }

    .k_cart_trainer_export_mobile .k_cart_trainer_export_box {
        width: 100%;
    }
}

@media screen and (max-width: 460px){
    /*.k_cart_trainer_export{
        display: none !important;
    }*/

    /*form.auth__form.profile__email-f.profile__container.k_profile_email_register {
        padding-left: 0px;
        padding-right: 0px;
    }*/

    .k_profile_email_register .df.register__df {
        flex-direction: column;
        gap: 0px;
    }

    p.k_password_info.style_1_caption_12_500.style_1_text_left {
        max-width: 100%;
    }

    .login__content.profile {
        min-height: fit-content;
    }
}