h1.rating_page_top_title {
    margin: 0;
    margin-top: 43px;
    position: relative;
}

.rating_page_top {
    align-items: end;
    -webkit-column-gap: 8px;
    column-gap: 8px;
    display: flex;
    justify-content: space-between;
}

span.rating_page_top_subtitle {
    margin-top: 14px;
    position: relative;
    display: block;
}

font.rating_page_top_date {
    color: grey;
}

section.rating_page_table {
    margin-top: 30px;
}

.rating_page_table_body, table.rating_page_table_content {
    width: 100%;
}

table.rating_page_table_content {
    background: #fff;
    border-collapse: collapse;
    border-radius: 32px 32px 32px 32px;
    display: flow-root;
    padding: 8px 20px;
    position: relative;
}

table.rating_page_table_content thead:before {
    background: #fff;
    border-radius: 32px 32px 0 0;
    content: "";
    height: 69.5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 8px;
}

table.rating_page_table_content thead tr {
    position: relative;
    position: -webkit-sticky;
}

.rating_page_table_content tr td, .rating_page_table_content tr th {
    border: 1px solid transparent;
    border-left: none;
    border-right: none;
    padding: 23px 0;
}

.rating_page_table_content tr th {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
}

.rating_page_table_content tr th {
    width: 14.4%;
}

.rating_page_table_content tr td:first-child, .rating_page_table_content tr th:first-child {
    text-align: center;
}

.rating_page_table_content tr th:first-child {
    left: 9px;
    position: relative;
    text-align: left;
}

.k_rating_table_r td:first-child, .rating_page_table_content thead tr th:first-child {
    min-width: 85px;
    padding-left: 20px;
    width: 85px;
}

.rating_page_table_content tr th img {
    float: left;
    margin-right: 8px;
}

.rating_page_table_content tr th font {
    align-items: center;
    display: flex;
    height: 20px;
}

.rating_page_table_content thead tr:after {
    background: #d9d9d9;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: -20px;
    position: absolute;
    right: -20px;
}

table.rating_page_table_content tbody {
    display: flow-root;
    margin-top: -1px;
}

table.rating_page_table_content tbody {
    min-height: 50vh;
}

.trainers__link {
    cursor: pointer;
    text-decoration: none;
}

tr.k_rating_table_r {
    border-radius: 16px!important;
    -webkit-clip-path: border-box;
    clip-path: border-box;
    position: relative;
    transition: 0.3s;
}

tr.k_rating_table_r:hover {
    background: #80808014;
    cursor: pointer;
}

.rating_page_table_content tbody tr:after {
    background: #d9d9d9;
    top: 0px;
    margin-top: 63px;
    content: "";
    display: block;
    height: 1px;
    left: 20px;
    position: absolute;
    right: 20px;
}

.rating_page_table_content tbody tr {
    position: relative;
    position: -webkit-sticky;
}

.rating_page_table_content tr td {
    color: grey;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
}

.rating_page_table_content tbody tr td {
    max-height: 10px;
    padding: 16px 0;
}

.rating_page_table_content tr td:first-child {
    line-height: 30px;
    padding-left: 18px;
    padding-right: 2px;
    position: relative;
    left: 0;
    top: 0;
}

.k_cart_trainer_rate_img_1 {
    background-image: url("../../assets/icons/rating/Top–1.svg");
}

.k_cart_trainer_rate_img_2{
    background-image: url("../../assets/icons/rating/Top–2.svg");
}

.k_cart_trainer_rate_img_3{
    background-image: url("../../assets/icons/rating/Top–3.svg");
}

.k_cart_trainer_rate_img_10{
    background-image: url("../../assets/icons/rating/Top–10.svg");
}

.k_cart_trainer_rate_img_30{
    background-image: url("../../assets/icons/rating/Top–30.svg");
}

.k_cart_trainer_rate_img_30,.k_cart_trainer_rate_img_10,.k_cart_trainer_rate_img_3,.k_cart_trainer_rate_img_2,.k_cart_trainer_rate_img_1{
    font-size: 0px;
}

.k_cart_trainer_rate_img {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
    left: 2px;
    position: relative;
}

td.rating_table_r_battles, td.rating_table_r_downloads, td.rating_table_r_rate, td.rating_table_r_sells, td.rating_table_r_trainer_name, td.rating_table_r_v_p, td.rating_table_r_wins {
    max-width: 10px;
    width: 14.4%;
}

td.rating_table_r_trainer_name.trainers__title-text {
    padding-right: 10px;
}

.trainers__title-text {
    color: #222;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    margin: 0;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

td.rating_table_r_rate, td.rating_table_r_trainer_name {
    color: #000!important;
}

.k_rating_table_r td:last-child, .rating_page_table_content thead tr th:last-child {
    padding-right: 20px;
}

table.rating_page_table_content.sticky {
    padding-top: 76px;
}

table.rating_page_table_content.sticky thead {
    background: #fff;
    border-radius: 32px 32px 0 0;
    box-shadow: 0 10px 20px 0 #00000014;
    display: table-cell;
    left: 40px;
    padding: 0 20px;
    position: fixed;
    right: 40px;
    top: 0;
    z-index: 999;
}

table.rating_page_table_content.sticky thead:before {
    height: 76px!important;
    top: 0;
}

table.rating_page_table_content.sticky tr th {
    padding-bottom: 24px!important;
    padding-top: 30px!important;
}

table.rating_page_table_content tr th {
    position: relative;
}

.rating_page_bottom_date {
    align-items: end;
    justify-content: flex-end;
    margin: 30px auto 80px !important;
    -webkit-column-gap: 8px;
    column-gap: 8px;
    display: flex;
    justify-content: end;
    color: grey;
}

tr.k_rating_table_r:last-child:after{
    display: none !important;
}

.k_cart_trainer_rate_img {
    color: #222222;
}

@media screen and (max-width: 1280px) {
    table.rating_page_table_content.sticky thead {
        left: 12px;
        right: 12px;
    }
}

@media screen and (max-width: 900px) {
    table.rating_page_table_content.sticky thead {
        left: 24px;
        right: 24px;
    }

    .hidden_mobile, .k_rating_span_right font {
        display: none;
    }
    table.rating_page_table_content {
        margin-bottom: 40px;
    }
    .rating_page_table_content tr th, td.rating_table_r_battles, td.rating_table_r_downloads, td.rating_table_r_rate, td.rating_table_r_sells, td.rating_table_r_trainer_name, td.rating_table_r_v_p, td.rating_table_r_wins {
        width: 50%;
    }

    .rating_page_top_date {
        display: none;
    }

    .rating_page {
        padding: 0 12px;
    }

    .rating_page_top_subtitle {
        font-size: 14px;
        text-align: left;
    }

    section.rating_page_table {
        margin-top: 16px;
    }

    h1.rating_page_top_title {
        margin-top: 16px;
    }

    .rating_page_bottom_date {
        opacity: 0;
    }

    table.rating_page_table_content {
        border-radius: 12px;
    }
}

@media screen and (max-width: 480px) {
    table.rating_page_table_content.sticky thead {
        left: 12px;
        right: 12px;
    }
}

@media screen and (min-width: 1600px){
    table.rating_page_table_content.sticky thead {
        left: 50px;
        right: 50px;
    }
}