h2.k_h2 {
    color: #222;
    font-family: Pokemon Classic;
    font-size: 26px;
    font-weight: 400;
    line-height: 42px;
    margin-bottom: 45px;
    text-align: center;
}

input.k_search_trace {
    border: 1px solid #f8f8f8;
    border-radius: 6px;
    display: block;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 57px;
    line-height: 17px;
    min-height: 57px;
    outline: none;
    padding: 30px 16px;
    transition: border .3s ease-in;
    width: 100%;
    background: white;
    box-sizing: border-box;
    z-index: 9999;
    position: relative;
}

.k_tracer_wrapper_search {
    margin: 0 15px 30px;
}

.k_tracer_one {
    margin: 10px 10px;
    background: white;
    z-index: 9;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.k_tracer_one_ttop {
    display: flex !important;
    border-radius: 10px;
    align-items: normal;
    justify-content: center;
    overflow: hidden;
}

.k_tracer_one_right_title {
    font-size: 13px;
    line-height: 14px;
    margin: 10px 0;
}

.k_tracer_one_right_open {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.k_tracer_one_right {
    float: right;
    width: 85%;
    min-height: 90px;
    position: relative;
    padding: 10px 10px 10px 10px;
    border: 1px solid #00000029;
    border-radius: 0px 10px 10px 0px;
}

.k_tracer_wrappers {
    padding-bottom: 80px;
}

.k_tracer_one_bottom {
    display: none;
}

.k_tracer_one_left {
    float: left;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    min-height: 90px;
}

.k_tracer_one_right_date {
    font-size: 11px;
    opacity: 0.5;
}

.k_tracer_one_right_speed {
    opacity: 0.5;
    font-size: 11px;
}

.k_tracer_one_right_open {
    position: absolute;
    right: 10px;
    top: -60px;
    display: flex;
    font-size: 18px;
    transform: rotate(90deg) scaleX(0.7);
    width: 20px;
    height: 20px;
    line-height: 18px;
    align-items: center;
    justify-content: center;
    transition: 0.7s;
}

.k_tracer_one_left.stat_200 {
    background: #78c178;
    color: white;
}

.k_tracer_one_left_id {
    font-size: 11px;
    margin-bottom: 5px;
}

.k_tracer_one_left_status {
    font-weight: bold;
    font-size: 14px;
}

.k_tracer_one_right_open.active {
    transform: rotate(270deg) scaleX(0.7);
}

.k_tracer_one_bottom_content {
    word-wrap: break-word;
    padding: 10px;
    font-size: 14px;
}

