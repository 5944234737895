@media screen and (min-width: 768px) {
    .k_debug_menu{
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .k_debug_menu {
        position: fixed;
        z-index: 9999;
        left: 0;
        /*visibility: hidden;*/
        margin-top: 0px;
        background: white;
    }
    .k_debug_menu_button {
        width: 40px;
        height: 50px;
        display: flex;
        justify-content: center;
        background: #ffba55;
        border-radius: 0px 10px 10px 0px;
        cursor: pointer;
        color: white;
        font-size: 20px;
        font-family: 'Pokemon Classic';
        line-height: 47px;
        margin-left: 0px;
        transition: 1s;
    }
    .k_debug_menu_console {
        width: 100%;
        background: white;
        border-radius: 0px;
        top: 0px;
        position: fixed;
        z-index: 99;
        padding: 5px;
        text-align: center;
        left: 0px;
        transition: 1s;
        max-height: 100%;
        overflow-y: scroll;
        padding-top: 15px;
    }
    .k_debug_menu_console.k_show {
        left: 0px;
    }
    .k_debug_menu_button.k_show {
        margin-left: 300px;
    }
    .k_debug_b_icon {
        transition: 1s;
    }
    .k_debug_menu_button.k_show .k_debug_b_icon {
        transform: scaleX(-1);
        margin-left: -10px;
    }
    .k_debug_menu_c_title {
        font-family: 'Pokemon Classic';
        font-size: 12px;
        margin-bottom: 15px;
    }
    .k_debug_menu_c_input {
        width: 80%;
        border: 1px solid #ffba55;
        border-radius: 5px;
        padding: 5px;
        font-size: 10px;
    }
    .k_debug_menu_c_b {
        background: #ffba55;
        border-radius: 10px;
        margin-top: 20px;
        color: white;
        padding: 10px 0px;
        cursor: pointer;
        font-size: 13px;
    }
    .k_debug_menu_c_delete {
        color: white;
        background: red;
    }
    .k_debug_pokemon.k_die .k_debug_menu_c_flex{
        opacity: 0.5;
        pointer-events: none;
    }
    .k_debug_menu_c_pokemons {
        padding: 5px;
        margin: 20px 0;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .k_debug_menu_c_pokemon {
        display: none;
    }
    .k_debug_menu_c_pokemon_photo img {
        width: 100%;
    }
    .k_debug_menu_c_pokemons .k_debug_menu_c_b {
        font-size: 10px;
        padding: 1px;
    }
    .k_debug_menu_c_pokemon_photo {
        width: 20%;
    }
    .k_debug_menu_c_pokemon_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .k_debug_menu_c_pokemon_active {
        margin-bottom: 20px;
        border: 1px solid #ffba55;
        padding: 2px;
        border-radius: 10px;
    }
    .k_debug_menu_c_pokemon_id {
        font-size: 10px;
        opacity: 0.5;
    }
    .k_debug_menu_c_pokemon_name {
        font-size: 10px;
        font-family: "Pokemon Classic";
        font-style: normal;
        font-weight: 400;
        color: #222222;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90px;
        margin-bottom: 2px;
    }
    .k_debug_menu_c_pokemon_pokeball_status {
        font-size: 9px;
    }
    .k_debug_menu_c_pokemon_actions .k_debug_menu_c_b {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 8px 0px;
        padding: 7px 0 !important;
        border: none;
    }
    .k_debug_menu_c_b.k_debug_menu_c_pokemon_pokeball_change {
        margin-top: 5px;
        padding: 8px 0;
        font-size: 13px;
    }
    .k_debug_menu_c_b.k_debug_menu_c_kill_pokemon {
        background: linear-gradient(90deg, #258f25 100%, red 100%);
        transition: 2s;
    }
    .k_debug_menu_c_pokemon_active.k_die .k_debug_menu_c_pokemon_top {
        opacity: 0.50;
        pointer-events: none;
    }
    .k_debug_menu_c_pokemon_pokeball {
        width: 46%;
    }
    .k_debug_menu_c_flex {
        width: 90%;
        margin: auto;
    }
    .k_debug_menu_c_flex label {
        width: 100%;
        display: block;
        text-align: left;
        font-size: 11px;
    }
    .k_debug_menu_c_input {
        width: 100%;
        border: 1px solid #ffba55;
        border-radius: 5px;
        padding: 10px;
        font-size: 10px;
        margin-top: 2px;
        margin-bottom: 10px;
    }
    .k_debug_menu_c_b.k_debug_menu_c_gen_pokemon {
        margin-bottom: -15px;
    }
    .k_debug_menu_c_flex .k_f_error, .k_debug_menu_c_pokemon_active .k_f_error, .k_debug_trainer > .k_f_error {
        width: 100%;
        font-size: 10px;
        color: red;
        margin-top: -5px;
        display: block;
        border: none !important;
        margin-bottom: 10px;
    }
    .k_debug_menu_c_pokemon_active .k_f_error {
        margin-top: 5px;
    }
    .k_debug_trainer > .k_f_error {
        margin-top: 20px;
    }
    .k_debug_menu_c_flex.k_pok_change_pokeball.k_die {
        opacity: 0.5;
        pointer-events: none;
    }
    .k_debug_pokemon .k_debug_menu_c_b.k_debug_menu_c_delete.k_debug_menu_c_delete_o_pokemon {
        margin-top: 5px;
    }
    .k_debug_photo_trainers{
        display: flow-root;
    }
    .k_debug_photo_trainer_example{
        display: none;
    }
    .k_debug_photo_trainer.feature-empty {
        filter: blur(1px) brightness(0.5);
    }
    .k_debug_photo_trainer {
        width: 25%;
        float: left;
        padding: 0px;
        margin: 0 !important;
        border: 2px solid #ff000000;
    }
    .k_debug_photo_trainer img{
        width: 100%;
    }
    .k_debug_photo_trainer:hover {
        border: 2px solid #ff000000 !important;
        box-shadow: none !important;
    }
}

.k_debug_menu_button {
    top: 50vh;
    position: absolute !important;
}