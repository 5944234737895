.k_field_passwords {
    display: flex;
    gap: 15px;
}

p.k_password_info {
    max-width: 170px;
    color: grey;
    margin-bottom: 45px;
    margin-top: 10px;
}

@media screen and (max-width: 460px){
    .k_field_passwords {
        flex-direction: column;
    }

    .k_field_passwords p.k_password_info {
        max-width: 100%;
    }

    .k_field_passwords .MuiFormControl-root:first-child {
        margin-bottom: -10px !important;
    }
}